import Cookies from 'cookies-js';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { accessTokenActions } from 'src/store/access/token/actions';
import { useAccessState } from 'src/store/access/hooks';
import { TOKEN, ACCESS_UNKNOWN } from 'src/constants/access';


const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const tokenFromCookies = Cookies.get(TOKEN);
  const { accessStatus } = useAccessState();

  useEffect(() => {
    if (accessStatus === ACCESS_UNKNOWN) {
      dispatch(accessTokenActions.checkToken(tokenFromCookies));
    }
  }, [accessStatus, tokenFromCookies]);

  // TODO: Add error handler
  return accessStatus !== ACCESS_UNKNOWN ? children : null;
};

export { AuthProvider };
