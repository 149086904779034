import PropTypes from 'prop-types';
import {
  SESSION_LIST_LEFT,
  SESSION_LIST_RIGHT,
  SPEAKER_LIST_LEFT,
  SPEAKER_LIST_RIGHT,
  PRIVATE_ADVERTISEMENTS,
  PUBLIC_ADVERTISEMENTS,
} from 'src/constants/advertisements';


const advertisementShape = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  pageArea: PropTypes.oneOf([
    SESSION_LIST_LEFT,
    SESSION_LIST_RIGHT,
    SPEAKER_LIST_LEFT,
    SPEAKER_LIST_RIGHT,
  ]).isRequired,
  view: PropTypes.string.isRequired,
  priority: PropTypes.number,
  language: PropTypes.string,
  display: PropTypes.oneOf([PRIVATE_ADVERTISEMENTS, PUBLIC_ADVERTISEMENTS]),
});

export { advertisementShape };
