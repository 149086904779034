import React, { useState } from 'react';
import BEM from 'bem-class-names-builder';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { useInitState } from 'src/store/init/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { ResetIcon } from 'src/view/components/FilterBox/components/ResetIcon';
import './styles.less';


const bem = new BEM('op-colorBy-box');

const ColorByBox = () => {
  const { t } = useTranslation();
  const { congressAppearance } = useInitState();
  const [isActive, setIsActive] = useState(true);
  const { colorBy } = useSearchParamsState();
  const { setSearchParams } = useSearchParamsDispatch();
  const { sessionColorBy: colorByOptions } = congressAppearance || {};
  const isBoxShown = colorByOptions && colorByOptions.length;

  return isBoxShown ? (
    <div className={bem}>
      <div className={bem.elem('header')} onClick={() => setIsActive(!isActive)}>
        <div className={bem.elem('header-title')}>
          <span>{t('colorBy')}</span>
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            className={bem.elem('caret')}
          />
        </div>
        {!isActive && <div className={bem.elem('preview')}>
          {colorBy}
        </div>}
      </div>
      {isActive && <div className={bem.elem('body')}>
        <Radio.Group
          onChange={e => setSearchParams({ colorBy: e.target.value })}
          value={colorBy}
        >
          {colorByOptions.map((option, i) =>
            <Radio
              value={option}
              key={i}
              className={bem.elem('item').toString()}
            >
              <span className={bem.elem('item__label')}>{option}</span>
              {colorBy === option && <ResetIcon
                className={bem.elem('reset-icon')}
                handleChange={() => setSearchParams({ colorBy: '' })}
              />}
            </Radio>,
          )}
        </Radio.Group>
      </div>}
    </div>
  ) : null;
};

export { ColorByBox };
