import { useSelector, useDispatch } from 'react-redux';
import { speakerFavoritesActions } from 'src/store/speakers/favorites/actions';
import { selectSpeakerFavorites, selectSpeakerFavoritesLoading } from 'src/store/speakers/favorites/selectors';


const useSpeakerFavoritesState = () => ({
  speakerFavorites: useSelector(selectSpeakerFavorites),
  isSpeakerFavoritesLoading: useSelector(selectSpeakerFavoritesLoading),
});

const useSpeakerFavoritesDispatch = () => {
  const dispatch = useDispatch();

  return ({
    fetchFavorites: () => dispatch(speakerFavoritesActions.fetch()),
    addSpeakerToFavorites: legacyId => dispatch(speakerFavoritesActions.add(legacyId)),
    removeSpeakerFromFavorites: legacyId => dispatch(speakerFavoritesActions.remove(legacyId)),
  });
};

export { useSpeakerFavoritesState, useSpeakerFavoritesDispatch };
