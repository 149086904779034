import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'src/view/components/Spinner';
import { TopFilter } from 'src/view/components/TopFilter';
import { filterShape } from 'src/view/propTypes/filter';


const Navigation = ({ isLoading, activeFilters, navigationFilters, setSearchParams }) => (
  <Spinner isLoading={isLoading}>
    {navigationFilters.map(({ name, ...filter }) => (
      <TopFilter
        {...filter}
        name={name}
        key={filter._id}
        activeFilter={activeFilters && activeFilters[name]}
        onChange={value => setSearchParams({ filters: { ...activeFilters, [name]: value }, offset: 0 })}
      />
    ))}
  </Spinner>
);

Navigation.propTypes = {
  activeFilters: PropTypes.object,
  setSearchParams: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  navigationFilters: PropTypes.arrayOf(filterShape),
};

Navigation.defaultProps = {
  navigationFilters: [],
};

export { Navigation };
