import { createActions } from 'src/modules/createActions';


const fileActions = createActions('file', [
  'DOWNLOAD',
  'DOWNLOAD_SUCCEEDED',
  'DOWNLOAD_FAILED',
]);

export { fileActions };
