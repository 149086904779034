import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover as PopoverCmp } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';


const Popover = ({ renderContent, setIsActive, isActive, title, style, bem, placement, styles }) => (
  <PopoverCmp
    content={renderContent}
    trigger="click"
    placement={placement}
    overlayStyle={{ ...styles }}
    onVisibleChange={() => setIsActive(!isActive)}
  >
    <Button
      className={bem.elem('filter-button').toString()}
      onClick={() => setIsActive(!isActive)}
    >
      {title}
      <CaretDownOutlined
        rotate={isActive ? 0 : 180}
        className={bem.elem('caret').toString()}
        style={{ ...style.caret }}
      />
    </Button>
  </PopoverCmp>
);

Popover.propTypes = {
  renderContent: PropTypes.node.isRequired,
  setIsActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
  bem: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
};

Popover.defaultProps = {
  placement: 'bottomLeft',
  styles: {
    maxWidth: '300px',
  },
};

export { Popover };
