import React from 'react';
import PropTypes from 'prop-types';
import BEM from 'bem-class-names-builder';
import { Spin } from 'antd';
import './styles.less';


const bem = new BEM('spinner');

const Spinner = ({ children, wrapperClassName, isLoading }) => (
  <Spin spinning={!!isLoading} wrapperClassName={bem.mix(wrapperClassName).toString()}>
    {children}
  </Spin>
);

Spinner.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClassName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

Spinner.defaultProps = {
  wrapperClassName: null,
};

export { Spinner };
