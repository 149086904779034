import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { sessionFavoritesActions } from 'src/store/sessions/favorites/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';


function* fetchSessionFavorites() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const favorites = yield call(selmaService.fetchFavorites);

    yield put(sessionFavoritesActions.fetchSucceeded(favorites.session));
  } catch (e) {
    yield put(sessionFavoritesActions.fetchFailed(e.message));
  }
}

function* addSessionFavorites({ payload: legacyId }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    yield call(selmaService.addSessionToFavorites, legacyId);
    yield put(sessionFavoritesActions.addSucceeded());
    yield put(sessionFavoritesActions.fetch());
  } catch (e) {
    yield put(sessionFavoritesActions.addFailed(e.message));
  }
}

function* removeSessionFavorites({ payload: legacyId }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    yield call(selmaService.removeSessionFromFavorites, legacyId);
    yield put(sessionFavoritesActions.removeSucceeded());
    yield put(sessionFavoritesActions.fetch());
  } catch (e) {
    yield put(sessionFavoritesActions.removeFailed(e.message));
  }
}

export { fetchSessionFavorites, addSessionFavorites, removeSessionFavorites };
