import { createReducer } from '@reduxjs/toolkit';
import { errorActions } from 'src/store/error/actions';


const errorReducer = createReducer(null, {
  [errorActions.set]: (state, action) => action.payload,
});

export { errorReducer };

