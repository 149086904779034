import { all, takeEvery } from 'redux-saga/effects';
import { sessionListActions } from 'src/store/sessions/list/actions';
import { speakerListActions } from 'src/store/speakers/list/actions';
import { initActions } from 'src/store/init/actions';
import { fetchSessions } from 'src/store/sessions/list/saga';
import { fetchSpeakers } from 'src/store/speakers/list/saga';
import { setClientToken, checkToken, setAccountToken, refreshToken } from 'src/store/access/token/saga';
import { fetchInit } from 'src/store/init/saga';
import { accessTokenActions } from 'src/store/access/token/actions';
import { sessionFiltersActions } from 'src/store/sessions/filters/actions';
import { speakerFiltersActions } from 'src/store/speakers/filters/actions';
import { sessionAdvertisementsActions } from 'src/store/sessions/advertisements/actions';
import { speakerAdvertisementsActions } from 'src/store/speakers/advertisements/actions';
import { searchParamsActions } from 'src/store/searchParams/actions';
import { fetchSessionFilters } from 'src/store/sessions/filters/saga';
import { fetchSpeakerFilters } from 'src/store/speakers/filters/saga';
import { fetchSessionAdvertisements } from 'src/store/sessions/advertisements/saga';
import { fetchSpeakerAdvertisements } from 'src/store/speakers/advertisements/saga';
import { speakerItemActions } from 'src/store/speakers/item/actions';
import { fetchSpeakerItem } from 'src/store/speakers/item/saga';
import { scopeSaga } from 'src/store/scope/saga';
import { setSearchParams } from 'src/store/searchParams/saga';
import { sessionFavoritesActions } from 'src/store/sessions/favorites/actions';
import { fetchSessionFavorites, addSessionFavorites, removeSessionFavorites } from 'src/store/sessions/favorites/saga';
import { speakerFavoritesActions } from 'src/store/speakers/favorites/actions';
import { fetchSpeakerFavorites, addSpeakerFavorites, removeSpeakerFavorites } from 'src/store/speakers/favorites/saga';
import { sessionItemActions } from 'src/store/sessions/item/actions';
import { fetchSessionItem } from 'src/store/sessions/item/saga';
import { calendarFiltersActions } from 'src/store/calendar/filters/actions';
import { fetchCalendarFilters } from 'src/store/calendar/filters/saga';
import { fileActions } from 'src/store/files/actions';
import { downloadFile } from 'src/store/files/saga';
import { sessionWatchTimeActions } from 'src/store/sessionWatchTime/actions';
import { createSessionWatchTime } from 'src/store/sessionWatchTime/saga';
import { fetchSessionCommentsList } from 'src/store/sessionComments/list/saga';
import { createSessionComment } from 'src/store/sessionComments/item/saga';
import { sessionCommentsListActions } from 'src/store/sessionComments/list/actions';
import { sessionCommentsItemActions } from 'src/store/sessionComments/item/actions';
import { personItemActions } from 'src/store/persons/item/actions';
import { getPerson } from 'src/store/persons/item/saga';
import { fetchCountries } from './countries/list/saga';
import { countryListActions } from './countries/list/actions';


function* sagas() {
  yield takeEvery(accessTokenActions.checkToken.type, checkToken);
  yield takeEvery(accessTokenActions.setClientToken.type, setClientToken);
  yield takeEvery(accessTokenActions.setAccountToken.type, setAccountToken);
  yield takeEvery(accessTokenActions.refreshToken.type, refreshToken);
  yield takeEvery(initActions.fetch.type, fetchInit);
  yield takeEvery(sessionListActions.fetch.type, fetchSessions);
  yield takeEvery(speakerListActions.fetch.type, fetchSpeakers);
  yield takeEvery(speakerItemActions.fetch.type, fetchSpeakerItem);
  yield takeEvery(sessionItemActions.fetch.type, fetchSessionItem);
  yield takeEvery(sessionFiltersActions.fetch.type, fetchSessionFilters);
  yield takeEvery(speakerFiltersActions.fetch.type, fetchSpeakerFilters);
  yield takeEvery(sessionAdvertisementsActions.fetch.type, fetchSessionAdvertisements);
  yield takeEvery(speakerAdvertisementsActions.fetch.type, fetchSpeakerAdvertisements);
  yield takeEvery(searchParamsActions.set.type, setSearchParams);
  yield takeEvery(sessionFavoritesActions.fetch.type, fetchSessionFavorites);
  yield takeEvery(sessionFavoritesActions.add.type, addSessionFavorites);
  yield takeEvery(sessionFavoritesActions.remove.type, removeSessionFavorites);
  yield takeEvery(speakerFavoritesActions.fetch.type, fetchSpeakerFavorites);
  yield takeEvery(speakerFavoritesActions.add.type, addSpeakerFavorites);
  yield takeEvery(speakerFavoritesActions.remove.type, removeSpeakerFavorites);
  yield takeEvery(calendarFiltersActions.fetch.type, fetchCalendarFilters);
  yield takeEvery(fileActions.download.type, downloadFile);
  yield takeEvery(sessionWatchTimeActions.create.type, createSessionWatchTime);
  yield takeEvery(sessionCommentsListActions.fetch.type, fetchSessionCommentsList);
  yield takeEvery(sessionCommentsItemActions.create.type, createSessionComment);
  yield takeEvery(personItemActions.fetch.type, getPerson);
  yield takeEvery(countryListActions.fetch.type, fetchCountries);
}

function* rootSaga() {
  yield all([
    scopeSaga(),
    sagas(),
  ]);
}

export { rootSaga };
