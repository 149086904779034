import { useSelector, useDispatch } from 'react-redux';
import { speakerListActions } from 'src/store/speakers/list/actions';
import {
  selectSpeakerList,
  selectSpeakerTotalCount,
  selectSpeakerListLoading,
} from 'src/store/speakers/list/selectors';


const useSpeakerListState = () => ({
  speakerList: useSelector(selectSpeakerList),
  speakerTotalCount: useSelector(selectSpeakerTotalCount),
  isSpeakerListLoading: useSelector(selectSpeakerListLoading),
});

const useSpeakerListDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSpeakerList: data => dispatch(speakerListActions.fetch(data)),
  });
};

export { useSpeakerListState, useSpeakerListDispatch };
