import { createActions } from 'src/modules/createActions';


const sessionFiltersActions = createActions('session-filters', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);


export { sessionFiltersActions };
