import { useEffect } from 'react';


const useCustomEventListener = ({ element, isInit }, eventName, eventHandler) => {
  useEffect(() => {
    const handleEvent = event => {
      const data = event.detail;

      eventHandler(data);
    };

    if (element) {
      element.addEventListener(eventName, handleEvent, false);
    }

    return () => {
      if (element) {
        element.removeEventListener(eventName, handleEvent, false);
      }
    };
  }, [isInit]);
};

export { useCustomEventListener };
