import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useSearchParamsState } from 'src/store/searchParams/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { SESSION_LIST_LEFT, SESSION_LIST_RIGHT } from 'src/constants/advertisements';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { SessionList } from 'src/view/pages/Sessions/List';
import { SessionFilters } from 'src/view/pages/Sessions/Filters';
import { SessionNavigation } from 'src/view/pages/Sessions/Navigation';
import { SessionAdvertisement } from 'src/view/pages/Sessions/Advertisement';
import { ColorByBox } from 'src/view/components/ColorByBox';
import { SearchBox } from 'src/view/components/SearchBox';
import { ClearFilters } from 'src/view/components/ClearFilters';


const Sessions = () => {
  const [isShowFilters, setShowFilters] = useState(true);
  const windowSize = useWindowSize();
  const { search } = useSearchParamsState();
  const [searchValue, setSearchValue] = useState(search);
  const { accessStatus, accessRoles } = useAccessState();

  const { getPerson } = usePersonDispatch();
  const { person } = usePersonState();

  useEffect(() => setShowFilters(windowSize.width < 1024), [windowSize.width]);
  useEffect(() => {
    if (accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT)) {
      getPerson();
    }
  }, [accessStatus, accessRoles]);

  return (
    <div className="op-layout">
      <header>
        <SessionNavigation />
      </header>
      <div className="op-container">
        <div className="op-banner">
          <SessionAdvertisement side={SESSION_LIST_LEFT} />
        </div>
        <div className="op-content">
          <section>
            {windowSize.width < 1024 && (
              <Button onClick={() => setShowFilters(!isShowFilters)} className='filter-button'>
                Filters
              </Button>)}
              <div className={`op-filters op-filters${isShowFilters ? '--hide' : ''}`}>
                <SearchBox
                  favoriteEntities={person?.sessionFavorites}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
                <div className='op-sessions-filters'>
                  <SessionFilters />
                </div>
                <ClearFilters searchValue={searchValue} setSearchValue={setSearchValue} />
                <ColorByBox />
              </div>
            <SessionList />
          </section>
        </div>
        <div className="op-banner">
          <SessionAdvertisement side={SESSION_LIST_RIGHT} />
        </div>
      </div>
    </div>
  );
};

export { Sessions };
