import React from 'react';
import ReactDOM from 'react-dom';
import BEM from 'bem-class-names-builder';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import { useConfigs } from 'src/hooks/useConfigs';


const bem = new BEM('op-session__headline');

const SessionHeadline = () => {
  const { isNEOSPlugin } = useConfigs();
  const { sessionItem } = useSessionItemState();
  const domElement = document.getElementById(bem);
  const { session } = sessionItem || {};
  const { title, public_number_full } = session || {};

  const publicNumberFull = public_number_full
    && <span className={bem.elem('headline-public-number').toString()}>{public_number_full} |</span>;

  const component = <h3>{publicNumberFull} {title}</h3>;

  return isNEOSPlugin ? domElement && ReactDOM.createPortal(component, domElement) : component;
};

export { SessionHeadline };
