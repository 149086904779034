import React, { useState } from 'react';
import BEM from 'bem-class-names-builder';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Button, Col, Input, Row } from 'antd';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Modal } from 'src/view/components/Modal';
import './styles.less';


const bem = new BEM('op-copy-button');

const ShareModal = ({ title, visible, handleClose, value }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    copy(value);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Modal
      scrollable
      title={title}
      visible={visible}
      handleClose={handleClose}
    >
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={16}>
          <Input value={value} disabled/>
        </Col>
        <Col xs={24} sm={8}>
          <Button onClick={handleCopy} type={'primary'} block className={bem.toString()}> {
            isCopied ? (
              <span>
                <CheckCircleOutlined/> {t('copied')}
              </span>
            ) : (
              <span>
                <CopyOutlined/> { t('copyToClipboard') }
              </span>
            )}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export { ShareModal };
