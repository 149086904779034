import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import BEM from 'bem-class-names-builder';
import { TimezoneContext } from 'src/contexts/TimezoneContext';
import './styles.less';


const bem = new BEM('op-time-marker');

const TimeMarker = ({ adjustTime, hoursRangeLength, timelineStart, timelineEnd, timelineItemSize, timeFormat }) => {
  const [currentTime, setCurrentTime] = useState(adjustTime(new Date()));
  const { timezone } = useContext(TimezoneContext);

  const agendaStartDate = moment(timelineStart);
  const agendaEndDate = moment(timelineEnd);

  const parentElementSize = (hoursRangeLength - 1) * timelineItemSize;
  const heightForMinute = parentElementSize / agendaEndDate.diff(agendaStartDate, 'minutes');

  const isMarkerWithinAgenda = currentTime.isAfter(agendaStartDate) && currentTime.isBefore(agendaEndDate);
  const markerTopIndent = (currentTime.diff(agendaStartDate, 'minutes') * heightForMinute);

  useEffect(() => {
    setCurrentTime(adjustTime(new Date()));
  }, [timezone]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(adjustTime(new Date()));
    }, 1000);

    return () => clearInterval(interval);
  });

  return isMarkerWithinAgenda && <div className={bem} style={{ top: markerTopIndent }}>
    <div className={bem.elem('timer').toString()}>
      {currentTime.format(timeFormat)}
    </div>
  </div>;
};

export { TimeMarker };
