import { createActions } from 'src/modules/createActions';


const speakerFiltersActions = createActions('speaker-filters', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);


export { speakerFiltersActions };
