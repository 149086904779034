import { useDispatch, useSelector } from 'react-redux';
import { personItemActions } from 'src/store/persons/item/actions';


const usePersonState = () => ({
  person: useSelector(state => state.person.item),
});

const usePersonDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getPerson: () => dispatch(personItemActions.fetch()),
  });
};

export { usePersonState, usePersonDispatch };
