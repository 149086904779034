import { Button } from 'antd';
import React from 'react';
import BEM from 'bem-class-names-builder';
import './styles.less';


const buttonsGroup = new BEM('op-buttons-group');
const button = new BEM('op-button');
const span = new BEM('op-button-text');

const Buttons = ({ buttons, direction }) => (
  <div className={buttonsGroup.mix(new BEM(direction))}>
    {
      buttons.map(({ type, label, icon, component, onClick, color }) => {
        const isPrimary = type === 'primary';

        return (
          <React.Fragment key={label}>
            <Button
              className={button.mods({ color, primary: isPrimary, default: !isPrimary }).toString()}
              key={label}
              onClick={onClick}
              icon={icon}
              type={type}
              title={label}
            >
              <span className={span}>{label}</span>
            </Button>
            {component}
          </React.Fragment>
        );
      })
    }
  </div>
);

export { Buttons };
