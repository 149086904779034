import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import {
  ShareAltOutlined, StarOutlined, VideoCameraOutlined,
  FileTextOutlined, FilePptOutlined,
  DownOutlined, CaretRightOutlined, CalendarOutlined,
  StarFilled, DownloadOutlined,
} from '@ant-design/icons';
import BEM from 'bem-class-names-builder';
import { Button } from 'antd';
import moment from 'moment';
import parse, { attributesToProps } from 'html-react-parser';
import { Modal } from 'src/view/components/Modal';
import { getSessionConfig, getCalendarConfig, getSessionContributionSelector } from 'src/utils/session';
import { getFormattedTimeRange } from 'src/utils/date';
import { downloadFile } from 'src/utils/downloadFile';
import { useInitState } from 'src/store/init/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { useFilesState, useFilesDispatch } from 'src/store/files/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { sessionShape } from 'src/view/propTypes/session';
import { ACCESS_ACCOUNT } from 'src/constants/access';
import { Buttons } from 'src/view/components/Buttons';
import { Snippet, HTMLParser } from 'src/view/components/Snippet';
import { Spinner } from 'src/view/components/Spinner';
import { VIEW_TYPE_INFO, VIEW_TYPE_OVERVIEW } from 'src/constants/session';
import { SocialButtons } from './components/SocialButtons';
import { ShareModal } from './components/ShareModal';
import './styles.less';


const bem = new BEM('op-session-item');

const SessionItem = ({ sessionViewType, ...session }) => {
  const { id, legacyId, listItemView, detailView, contributions, presentationURL, abstract, premium,
    playerUrl, isFavorite, liveStream, onDemand, start_date_time, end_date_time, _self } = session;

  const { t } = useTranslation();
  const { congress, onlineprogram, congressTimeFormat, congressDateFormat } = useInitState();
  const { accessStatus } = useAccessState();
  const { isFileLoading } = useFilesState();
  const { downloadFile: downloadFileFromFilus } = useFilesDispatch();

  const { person } = usePersonState();
  const { updatePerson } = usePersonDispatch();

  const [isCollapsed, setCollapsed] = useState(true);
  const [contributionAbstractId, setContributionAbstractId] = useState(null);
  const [abstractId, setAbstractId] = useState(null);
  const [isShareModal, setIsShareModal] = useState(false);

  const sessionView = (sessionViewType === VIEW_TYPE_OVERVIEW || sessionViewType === VIEW_TYPE_INFO)
    ? detailView
    : listItemView;

  const downloadCalendarFile = () => {
    const sessionConfig = getSessionConfig(congress, session);
    const calendarConfig = getCalendarConfig(sessionConfig);

    downloadFile(calendarConfig, { type: 'text/calendar' }, `${sessionConfig.summary}.ics`);
  };

  const isActiveCalendarExport = Boolean(session.start_date_time && session.end_date_time);

  const isLiveNow = liveStream?.isAvailable;
  const isOnDemand = onDemand?.isAvailable;
  const currentPlayerUrl = `${playerUrl || onlineprogram?.defaultPlayerUrl}?sessionId=${id}`;

  const socialButtons = [
    {
      title: t('favorite'),
      icon: StarOutlined,
      filledIcon: StarFilled,
      onClick: () => updatePerson({
        _id: person._id,
        sessionFavorites: isFavorite
          ? person.sessionFavorites.filter(({ $ref }) => $ref !== _self.$ref)
          : [...person.sessionFavorites, { $ref: _self.$ref }],
      }),
      isHidden: accessStatus !== ACCESS_ACCOUNT,
      isActive: isFavorite,
    },
    {
      title: t('iCalendar'),
      icon: CalendarOutlined,
      onClick: downloadCalendarFile,
      isHidden: !isActiveCalendarExport,
    },
    {
      title: t('share'),
      icon: ShareAltOutlined,
      component: (
        <ShareModal
          title={t('shareTheEvent')}
          visible={isShareModal}
          handleClose={() => setIsShareModal(false)}
          value={onlineprogram?.sessionDetailPageUrl
            ? `${onlineprogram?.sessionDetailPageUrl}?sessionId=${id}`
            : currentPlayerUrl
          }
        />
      ),
      onClick: () => setIsShareModal(true),
      isHidden: false,
    },
  ];

  const buttons = [
    {
      isHidden: !isLiveNow || sessionViewType === VIEW_TYPE_INFO,
      label: t('liveNow'),
      icon: <CaretRightOutlined/>,
      type: 'primary',
      onClick: () => {
        window.location.href = currentPlayerUrl;
      },
    },

    {
      isHidden: !isOnDemand || (isLiveNow && isOnDemand) || sessionViewType === VIEW_TYPE_INFO,
      label: t('recording'),
      icon: <VideoCameraOutlined/>,
      type: 'primary',
      onClick: () => {
        window.location.href = currentPlayerUrl;
      },
    },

    {
      isHidden: !abstract?._id,
      label: t('abstract'),
      icon: <FileTextOutlined/>,
      type: 'primary',
      component: (
        <Modal
          scrollable
          visible={abstract?._id === abstractId}
          handleClose={() => setAbstractId(null)}
          footer={[
            (abstract?.content_url
              && <Button
                className={bem.elem('download-abstract').toString()}
                key="download"
                type="primary"
                onClick={() => saveAs(abstract.content_url)}
              >
                <DownloadOutlined />
                {t('downloadAbstract')}
              </Button>
            ),
            <Button key="submit" type="primary" onClick={() => setAbstractId(null)}>
              {t('ok')}
            </Button>,
          ]}>
          <Snippet selector=".session-abstract" view={sessionView} />
        </Modal>
      ),
      onClick: () => setAbstractId(abstract._id),
    },

    {
      isHidden: !presentationURL,
      label: t('presentationSlides'),
      icon: <FilePptOutlined/>,
      type: 'primary',
      onClick: () => downloadFileFromFilus(presentationURL._file, presentationURL.filename),
    },
  ].filter(b => !b.isHidden);

  const getContributionButtons = ({
    onDemand: contributionOnDemand, abstract: contributionAbstract,
    playerUrl: contributionPlayerUrl, presentationURL: contributionPresentationURL,
    index, _id: contributionId,
  }) => ([
    {
      isHidden: !contributionOnDemand?.isAvailable,
      label: t('recording'),
      icon: <VideoCameraOutlined/>,
      type: 'primary',
      onClick: () => {
        window.location.href = `${contributionPlayerUrl || onlineprogram?.defaultPlayerUrl}?sessionId=${contributionId}`;
      },
    },

    {
      isHidden: !contributionAbstract?._id,
      label: t('abstract'),
      icon: <FileTextOutlined/>,
      component: (
        <Modal
          scrollable
          visible={contributionAbstract?._id === contributionAbstractId}
          handleClose={() => setContributionAbstractId(null)}
          footer={[
            (contributionAbstract?.content_url
              && <Button
                className={bem.elem('download-abstract').toString()}
                key="download"
                type="primary"
                onClick={() => saveAs(contributionAbstract.content_url)}
              >
                <DownloadOutlined />
                {t('downloadAbstract')}
              </Button>
            ),
            <Button key="submit" type="primary" onClick={() => setContributionAbstractId(null)}>
              {t('ok')}
            </Button>,
          ]}>
          <Snippet
            view={sessionView}
            key={index}
            selector={
              `.session-contributions .contribution-item:nth-child(${index + 1}) .session-contribution-abstract`
            }/>
        </Modal>
      ),
      onClick: () => setContributionAbstractId(contributionAbstract._id),
    },

    {
      isHidden: !contributionPresentationURL,
      label: t('presentationSlides'),
      icon: <FilePptOutlined/>,
      onClick: () => downloadFileFromFilus(contributionPresentationURL._file, contributionPresentationURL.filename),
    },
  ].filter(b => !b.isHidden));

  const time = getFormattedTimeRange(start_date_time, end_date_time, congressTimeFormat);
  const date = moment(start_date_time).format(congressDateFormat?.short);

  const adjustedListItemView = sessionView && parse(sessionView, {
    replace: domNode => {
      const { attribs, name } = domNode || {};

      if (attribs?.['data-op-replacement']?.includes('op-session-time') || attribs?.class?.includes('replaceable-time')) {
        const attributes = attributesToProps(attribs);

        return React.createElement(name, attributes, time);
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-date') || attribs?.class?.includes('replaceable-date')) {
        const attributes = attributesToProps(domNode.attribs);

        return React.createElement(name, attributes, date);
      }

      return domNode;
    },
  });

  const getItemViewSnippet = className =>
    adjustedListItemView?.find(elem => elem.props?.className?.includes(className));

  const sessionItemRender = (
    <div className={bem.mix(
      !!session.topics?.length && `topic-${session.topics.map(topic => topic.id).join('-')}`,
      !!session.topics?.length && `topic-${session?.topics[0]?.id}`,
      session.track?.id && `track-${session.track?.id}`,
      session.session_type?.id && `session_type-${session.session_type?.id}`,
      session.start_date && `start_date-${moment(session.start_date).format('YYYY-MM-DD')}`,
      session.room?.id && `room-${session.room?.id}`,
      `session-${legacyId}`,
      (premium ? [...premium] : []),
    ).toString()}
         key={legacyId}
    >
      {
        !!contributions.length
        && <DownOutlined
          className={bem.elem('caret').toString()}
          style={{ fontSize: '16px' }}
          rotate={isCollapsed ? 0 : 180}
          onClick={() => setCollapsed(!isCollapsed)}
        />
      }
      <SocialButtons buttons={socialButtons}/>
      <div className={bem.elem('body').toString()}>
        {getItemViewSnippet('session-body')}
        <div className={bem.elem('buttons').toString()}>
          <Buttons buttons={buttons} />
          <HTMLParser selector=".session-additional-buttons" view={sessionView} />
        </div>
        <div className={bem.elem('contributions').mods({ hidden: isCollapsed }).toString()}>
          {contributions.map((contribution, index) => (
            <div className={bem.elem('contribution-item').toString()} key={index}>
              <div className={bem.elem('contribution-info').toString()}>
                <Snippet
                  view={sessionView}
                  key={index}
                  selector={getSessionContributionSelector(index)}
                />
              </div>
              <div className={bem.elem('buttons').toString()}>
                <Buttons buttons={getContributionButtons({ index, ...contribution })}/>
                <HTMLParser selector={`.session-contributions .contribution-item:nth-child(${index + 1}) .session-contribution-additional-buttons`} view={sessionView} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const sessionOverviewRender = (
    <Spinner isLoading={isFileLoading}>
      <div className={premium ? bem.mods('overview').mix(...premium, `session-${legacyId}`) : bem.mods('overview')} key={legacyId}>
        <div className={bem.elem('header')}>
          <Snippet selector=".session-header" view={sessionView} />
          <SocialButtons direction={'row'} buttons={socialButtons}/>
        </div>
        <Snippet selector=".session-video-player" view={sessionView} />
        <div className={bem.elem('buttons').toString()}>
          <Buttons buttons={buttons} />
          <HTMLParser selector=".session-additional-buttons" view={sessionView} />
        </div>
        <div className={bem.elem('body')}>
          <div className={bem.elem('contributions').toString()}>
            {contributions.map((contribution, index) => (
              <div className={bem.elem('contribution-item').toString()} key={index}>
                <div className={bem.elem('contribution-info').toString()}>
                  <Snippet
                    view={sessionView}
                    key={index}
                    selector={getSessionContributionSelector(index)}
                  />
                </div>
                <div className={bem.elem('buttons').toString()}>
                  <Buttons buttons={getContributionButtons({ index, ...contribution })}/>
                  <HTMLParser selector={`.session-contributions .contribution-item:nth-child(${index + 1}) .session-contribution-additional-buttons`} view={sessionView} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spinner>
  );

  const sessionInfoRender = (
    <div
      className={bem.mix(
        !!session.topics?.length && `topic-${session.topics.map(topic => topic.id).join('-')}`,
        session.track?.id && `track-${session.track?.id}`,
        session.session_type?.id && `session_type-${session.session_type?.id}`,
        session.start_date && `start_date-${moment(session.start_date).format('YYYY-MM-DD')}`,
        session.room?.id && `room-${session.room?.id}`,
        `session-${legacyId}`,
        (premium ? [...premium] : []),
      ).mods('info').toString()}
      key={legacyId}
    >
      <div className={bem.elem('body')}>
        {getItemViewSnippet('session-body')}
        <div className={bem.elem('buttons').toString()}>
          <Buttons buttons={buttons} />
          <HTMLParser selector=".session-additional-buttons" view={sessionView} />
        </div>
        <div className={bem.elem('contributions').toString()}>
          {contributions.map((contribution, index) => (
            <div className={bem.elem('contribution-item').toString()} key={index}>
              <div className={bem.elem('contribution-info').toString()}>
                <Snippet
                  view={sessionView}
                  key={index}
                  selector={getSessionContributionSelector(index)}
                />
              </div>
              <div className={bem.elem('buttons').toString()}>
                <Buttons buttons={getContributionButtons({ index, ...contribution })}/>
                <HTMLParser selector={`.session-contributions .contribution-item:nth-child(${index + 1}) .session-contribution-additional-buttons`} view={sessionView} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  if (sessionViewType === VIEW_TYPE_OVERVIEW) {
    return sessionOverviewRender;
  }

  if (sessionViewType === VIEW_TYPE_INFO) {
    return sessionInfoRender;
  }

  return sessionItemRender;
};

SessionItem.propTypes = {
  session: sessionShape,
};

SessionItem.defaultProps = {
  session: {},
};

export { SessionItem };
