import { createActions } from 'src/modules/createActions';


const initActions = createActions('init', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);

export { initActions };
