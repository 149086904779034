import React from 'react';
import ReactDOM from 'react-dom';
import { useConfigs } from 'src/hooks/useConfigs';
import { CommentsInput } from 'src/view/components/CommentsInput/';

/**
 * Component for rendering a comments input
 *
 * @category Pages/Components
 */
const SessionCommentsInput = () => {
  const { isNEOSPlugin } = useConfigs();
  const domElement = document.getElementById('op-session__comments-input');

  return isNEOSPlugin
    ? domElement && ReactDOM.createPortal(<CommentsInput />, domElement)
    : <CommentsInput />;
};

export { SessionCommentsInput };
