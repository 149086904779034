import { useSelector } from 'react-redux';
import { selectError } from 'src/store/error/selectors';


const useErrorState = () => ({
  error: useSelector(selectError),
});


export { useErrorState };
