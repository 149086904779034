import React from 'react';
import BEM from 'bem-class-names-builder';
import './styles.less';


const bem = new BEM('op-container-center');

const Container = ({ children }) => (
  <div className={bem}>{children}</div>
);

export { Container };
