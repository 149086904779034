import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { sessionItemActions } from 'src/store/sessions/item/actions';
import { createLoadingReducer } from 'src/modules/loadingReducer';


const sessionItemReducer = combineReducers({
  entity: createReducer(null, {
    [sessionItemActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionItemActions.fetch.type],
      stop: [sessionItemActions.fetchSucceeded.type, sessionItemActions.fetchFailed.type],
    },
  ]),
});

export { sessionItemReducer };
