import { createSelector } from 'reselect';
import { NAVIGATION_FILTER_TYPE } from 'src/constants/filters';


const selectCalendarFilters = state => state.calendar.filters.entries;
const selectCalendarFiltersLoading = state => state.calendar.filters.loading > 0;

const selectCalendarAdditionalFilters = createSelector(
  state => selectCalendarFilters(state),
  filters => (filters || []).filter(item => item.view !== NAVIGATION_FILTER_TYPE),
);

export {
  selectCalendarFilters,
  selectCalendarAdditionalFilters,
  selectCalendarFiltersLoading,
};
