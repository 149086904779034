import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { routes } from 'src/routes';
import { Sessions } from 'src/view/pages/Sessions';
import { Speakers } from 'src/view/pages/Speakers';
import { SpeakerOverview } from 'src/view/pages/Speakers/Overview';
import { Calendar } from 'src/view/pages/Calendar';
import { SessionOverview } from 'src/view/pages/Sessions/Overview';
import { SessionPlayerView } from 'src/view/pages/SessionPlayerView';
import { useConfigs } from 'src/hooks/useConfigs';
import 'src/view/styles/reset.less';


const Main = () => {
  const location = useLocation();
  const { sessionId, pathname } = useConfigs();

  return (
    <div className="app">
      {sessionId ? (
        <Switch location={{ pathname }}>
          <Route path={routes.sessions} component={() => <SessionOverview />} />
          <Route path={routes.speakers} component={() => <SpeakerOverview />} />
          <Route path={routes.video} component={() => <SessionPlayerView />} />
        </Switch>
      ) : (
        <Switch location={{ pathname }}>
          <Redirect exact from={routes.root} to={`${routes.sessions}${location.search}`} />
          <Route path={routes.sessions} component={Sessions} />
          <Route path={routes.speakers} component={Speakers} />
          <Route path={routes.calendar} component={Calendar} />
        </Switch>
      )}
    </div>
  );
};

export { Main };
