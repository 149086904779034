import invariant from 'invariant';


const flatten = arr => arr.reduce((newArr, item) => newArr.concat(item), []);

const createLoadingReducer = list => {
  const startList = flatten(list.map(({ start }) => start));
  const stopList = flatten(list.map(({ stop }) => stop));

  invariant([...stopList, ...startList].every(name => name != null), 'Passed empty value to createLoadingReducer');

  const startSet = new Set(startList);
  const stopSet = new Set(stopList);

  return (state = 0, action) => {
    if (startSet.has(action.type)) {
      return state + 1;
    }

    if (stopSet.has(action.type)) {
      return state - 1;
    }

    return state;
  };
};


export { createLoadingReducer };
