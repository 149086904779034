import { createAction } from '@reduxjs/toolkit';


const toCamelCase = type =>
  type
    .split('_')
    .map((part, index) => {
      const lowerCase = part.toLowerCase();
      const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);

      return index === 0 ? lowerCase : camelCase;
    })
    .join('');


const createActions = (prefix, actionsList) => Object.assign({},
  ...actionsList.map(type => ({ [toCamelCase(type)]: createAction(`${prefix}/${type}`) })));

export { createActions };
