import { createEvaEntityService } from '@globit/api-services';
import { config } from 'src/configs';


const createCountryService = (proxy, scope) => {
  const countryService = createEvaEntityService(config.api.services.eva, scope, 'country', proxy);

  return { ...countryService };
};

export { createCountryService };
