import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { speakerFavoritesActions } from 'src/store/speakers/favorites/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';


function* fetchSpeakerFavorites() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const favorites = yield call(selmaService.fetchFavorites);

    yield put(speakerFavoritesActions.fetchSucceeded(favorites.speaker));
  } catch (e) {
    yield put(speakerFavoritesActions.fetchFailed(e.message));
  }
}

function* addSpeakerFavorites({ payload: legacyId }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    yield call(selmaService.addSpeakerToFavorites, legacyId);
    yield put(speakerFavoritesActions.addSucceeded());
    yield put(speakerFavoritesActions.fetch());
  } catch (e) {
    yield put(speakerFavoritesActions.addFailed(e.message));
  }
}

function* removeSpeakerFavorites({ payload: legacyId }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    yield call(selmaService.removeSpeakerFromFavorites, legacyId);
    yield put(speakerFavoritesActions.removeSucceeded());
    yield put(speakerFavoritesActions.fetch());
  } catch (e) {
    yield put(speakerFavoritesActions.removeFailed(e.message));
  }
}

export { fetchSpeakerFavorites, addSpeakerFavorites, removeSpeakerFavorites };
