const SESSION_LIST_LEFT = 'session-list-left';
const SESSION_LIST_RIGHT = 'session-list-right';
const SPEAKER_LIST_LEFT = 'speaker-list-left';
const SPEAKER_LIST_RIGHT = 'speaker-list-right';
const PRIVATE_ADVERTISEMENTS = 'private';
const PUBLIC_ADVERTISEMENTS = 'public';


export {
  SESSION_LIST_LEFT,
  SESSION_LIST_RIGHT,
  SPEAKER_LIST_LEFT,
  SPEAKER_LIST_RIGHT,
  PRIVATE_ADVERTISEMENTS,
  PUBLIC_ADVERTISEMENTS,
};
