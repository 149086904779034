import { createActions } from 'src/modules/createActions';


const countryListActions = createActions('country-list', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);


export { countryListActions };
