import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { speakerFiltersActions } from 'src/store/speakers/filters/actions';


const speakerFiltersReducer = combineReducers({
  entries: createReducer(null, {
    [speakerFiltersActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [speakerFiltersActions.fetch.type],
      stop: [speakerFiltersActions.fetchSucceeded.type, speakerFiltersActions.fetchFailed.type],
    },
  ]),
});

export { speakerFiltersReducer };
