import React, { useEffect } from 'react';
import BEM from 'bem-class-names-builder';
import { useSpeakerItemState, useSpeakerItemDispatch } from 'src/store/speakers/item/hooks';
import { SpeakersItem } from 'src/view/pages/Speakers/Item';
import { Spinner } from 'src/view/components/Spinner';
import './styles.less';


const bem = new BEM('speaker');

const SpeakerOverview = () => {
  const { speakerItem, speakerId, isSpeakerItemLoading } = useSpeakerItemState();
  const { getSpeakerItem } = useSpeakerItemDispatch();

  useEffect(() => {
    getSpeakerItem(speakerId);
  }, []);

  return speakerItem && (
    <Spinner isLoading={isSpeakerItemLoading} wrapperClassName={bem}>
      <SpeakersItem {...speakerItem} isOverview />
    </Spinner>
  );
};

export { SpeakerOverview };
