import React, { useState, useEffect } from 'react';
import { CongFormComponent } from 'src/view/components/CongFormComponent';
import { Modal } from 'src/view/components/Modal';
import { useCustomEventListener } from 'src/hooks/useCustomEventListener';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { usePersonState } from 'src/store/persons/item/hooks';
import './styles.less';


const SessionModal = () => {
  const { sessionItem, sessionId } = useSessionItemState();
  const { person } = usePersonState();
  const industryConsentStorage = useLocalStorage('industryConsent', null);
  const { industryConsent } = industryConsentStorage || {};
  const data = sessionItem && (sessionItem?.parent || sessionItem?.session);

  const [isVisible, setIsVisible] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const preViewModal = data?.preViewModal;
  const showIndustryModal = !(industryConsent && Object.keys(industryConsent).includes(sessionId));

  useCustomEventListener(
    { element: document.getElementById('cong-form'), isInit },
    'Op:closeModal',
    () => setIsVisible(false),
  );

  useEffect(() => {
    if (preViewModal && person?._self?.$ref && showIndustryModal) {
      setIsVisible(true);
    }
  }, [data]);

  return (
    <Modal closable={false} visible={isVisible} width={600}>
      <CongFormComponent setIsInit={setIsInit} params={{
        entityType: 'session',
        entityId: sessionId,
        ...preViewModal,
      }}/>
    </Modal>
  );
};

export { SessionModal };
