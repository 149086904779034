import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { sessionCommentsItemActions } from 'src/store/sessionComments/item/actions';
import { createLoadingReducer } from 'src/modules/loadingReducer';


const sessionCommentsItemReducer = combineReducers({
  entry: createReducer(null, {
    [sessionCommentsItemActions.createSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionCommentsItemActions.create.type],
      stop: [sessionCommentsItemActions.createSucceeded.type, sessionCommentsItemActions.createFailed.type],
    },
  ]),
});

export { sessionCommentsItemReducer };
