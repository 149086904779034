import PropTypes from 'prop-types';
import { sessionShape } from 'src/view/propTypes/session';


const speakerShape = PropTypes.shape({
  id: PropTypes.string,
  view: PropTypes.string,
  contributions: PropTypes.arrayOf(PropTypes.shape({
    parentSession: sessionShape,
  })),
  profileImage: PropTypes.string,
});

export { speakerShape };
