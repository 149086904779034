import { createActions } from 'src/modules/createActions';


const speakerItemActions = createActions('speaker-item', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);


export { speakerItemActions };
