import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { store, history } from 'src/store/store';
import { InitProvider } from 'src/view/InitProvider';
import { routes } from 'src/routes';
import { Main } from 'src/view/pages/Main';
import { ErrorHandler } from 'src/view/pages/ErrorHandler';
import { TimezoneProvider } from 'src/contexts/TimezoneContext';
import { AuthProvider } from './AuthProvider';
import { CountriesProvider } from './CountriesProvider';
import 'src/view/styles/index.less';


const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthProvider>
        <InitProvider>
          <CountriesProvider>
            <TimezoneProvider>
              <ErrorHandler>
                <Switch>
                  <Route path={routes.root} component={Main} />
                </Switch>
              </ErrorHandler>
            </TimezoneProvider>
          </CountriesProvider>
        </InitProvider>
      </AuthProvider>
    </ConnectedRouter>
  </Provider>
);

export { App };
