import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { TimezoneContext } from 'src/contexts/TimezoneContext';


const TimezoneSwitcher = ({ onChange }) => {
  const { t } = useTranslation();
  const { timezone: currentTimezone, timezones, setTimezone } = useContext(TimezoneContext);

  const options = (
    <Menu onClick={({ key }) => {
      setTimezone(key);

      if (onChange) {
        onChange();
      }
    }}>
      {timezones && timezones.map(timezone => (
        <Menu.Item key={timezone}>
          {t(timezone)}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={options}>
      <Button>
        {t(currentTimezone)}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export { TimezoneSwitcher };
