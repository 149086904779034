import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { createAuthProxy } from 'src/store/access/utils';
import { sessionItemActions } from 'src/store/sessions/item/actions';
import { store } from 'src/store/store';
import { selectScope } from 'src/store/scope/selectors';


function* fetchSessionItem({ payload: id }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const session = yield call(selmaService.getSessionItem, id);

    yield put(sessionItemActions.fetchSucceeded(session));
  } catch (e) {
    yield put(sessionItemActions.fetchFailed(e.message));
  }
}

export { fetchSessionItem };
