import React from 'react';
import { useSelector } from 'react-redux';
import { useSessionFiltersState } from 'src/store/sessions/filters/hooks';
import { useSearchParamsDispatch, useSearchParamsState } from 'src/store/searchParams/hooks';
import { selectSessionNavigationFilters } from 'src/store/sessions/filters/selectors';
import { Navigation } from 'src/view/components/Navigation';


const SessionNavigation = () => {
  const { filters } = useSearchParamsState();
  const { isSessionFiltersLoading } = useSessionFiltersState();
  const { setSearchParams } = useSearchParamsDispatch();
  const sessionNavigationFilters = useSelector(selectSessionNavigationFilters);

  return (
    <Navigation
      isLoading={isSessionFiltersLoading}
      activeFilters={filters}
      navigationFilters={sessionNavigationFilters}
      setSearchParams={setSearchParams}
    />
  );
};

export { SessionNavigation };
