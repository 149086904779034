import { combineReducers } from 'redux';
import { accessStatusReducer } from 'src/store/access/status/reducer';
import { accessTokenReducer } from 'src/store/access/token/reducer';
import { accessRolesReducer } from 'src/store/access/roles/reducer';
import { accessRefReducer } from 'src/store/access/ref/reducer';


const accessRouter = combineReducers({
  status: accessStatusReducer,
  token: accessTokenReducer,
  roles: accessRolesReducer,
  ref: accessRefReducer,
});

export { accessRouter };
