import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { calendarFiltersActions } from 'src/store/calendar/filters/actions';


const calendarFiltersReducer = combineReducers({
  entries: createReducer([], {
    [calendarFiltersActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [calendarFiltersActions.fetch.type],
      stop: [calendarFiltersActions.fetchSucceeded.type, calendarFiltersActions.fetchFailed.type],
    },
  ]),
});

export { calendarFiltersReducer };
