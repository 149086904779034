import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import BEM from 'bem-class-names-builder';
import { useInitState } from 'src/store/init/hooks';
import { useSessionListState, useSessionListDispatch } from 'src/store/sessions/list/hooks';
import { useSearchParamsState } from 'src/store/searchParams/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { calculateDateTime } from 'src/utils/date';
import { TimezoneContext } from 'src/contexts/TimezoneContext';
import { ColorByBox } from 'src/view/components/ColorByBox';
import { ClearFilters } from 'src/view/components/ClearFilters';
import { DaySchedule } from 'src/view/components/DaySchedule';
import { SearchBox } from 'src/view/components/SearchBox';
import { CalendarFilters } from 'src/view/pages/Calendar/Filters';
import './styles.less';


const bem = new BEM('op-calendar');

const Calendar = () => {
  const { sessionList, isSessionListLoading } = useSessionListState();
  const { congressRooms = [], congressTimeFormat = 'HH:mm', calendarTimeSlotHeight, onlineprogram } = useInitState();
  const searchParams = useSearchParamsState();
  const { getSessionsList } = useSessionListDispatch();
  const { accessStatus, accessRoles } = useAccessState();
  const [searchValue, setSearchValue] = useState(searchParams.search);
  const { calendarView } = onlineprogram || {};

  const { getPerson } = usePersonDispatch();
  const { person } = usePersonState();

  useEffect(() => {
    if (accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT)) {
      getPerson();
    }
  }, [accessStatus, accessRoles]);

  const getRoomsInRightOrder = rooms => congressRooms.filter(r => rooms.includes(r));

  const roomsFromSessions = sessions =>
    [...new Set(sessions.filter(({ room }) => room?.name).map(({ room: { name } }) => name))];

  const rooms = roomsFromSessions(sessionList) || [];

  useEffect(() => {
    if (searchParams.activeDay) getSessionsList({ ...searchParams, viewMode: 'calendar', limit: -1 });
  }, [JSON.stringify(searchParams)]);

  const { venueTimezoneOffset, timezone } = useContext(TimezoneContext);
  const adjustTime = calculateDateTime(venueTimezoneOffset, timezone);
  const sessionsToDisplay = sessionList
    .map(session => ({
      ...session,
      start_date_time: adjustTime(session.start_date_time),
      end_date_time: adjustTime(session.end_date_time),
    }))
    .filter(({ start_date_time, end_date_time }) =>
      start_date_time.isSame(searchParams.activeDay, 'day') && end_date_time.isSame(searchParams.activeDay, 'day'))
    .sort((a, b) => a.start_date_time - b.end_date_time);

  const earliestSession = moment.min(sessionsToDisplay.map(({ start_date_time }) => start_date_time));
  const latestSession = moment.max(sessionsToDisplay.map(({ end_date_time }) => end_date_time));

  const earliestSessionTime = earliestSession?.clone();
  const latestSessionTime = latestSession?.clone();

  return (
    <div className={bem}>
      <div className={bem.elem('top-bar').toString()}>
        <SearchBox
          favoriteEntities={person?.sessionFavorites}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <CalendarFilters />
        <ClearFilters searchValue={searchValue} setSearchValue={setSearchValue} />
        <ColorByBox />
      </div>
      <DaySchedule
        isLoading={isSessionListLoading}
        rooms={getRoomsInRightOrder(rooms)}
        sessions={sessionsToDisplay}
        selectedDate={searchParams.activeDay}
        adjustTime={adjustTime}
        timeStart={earliestSessionTime}
        timeEnd={latestSessionTime}
        timeFormat={congressTimeFormat}
        timelineItemSize={calendarTimeSlotHeight}
        calendarView={calendarView}
      />
    </div>
  );
};

export { Calendar };
