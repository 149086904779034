import React from 'react';
import { useCalendarFiltersState, useCalendarFiltersDispatch } from 'src/store/calendar/filters/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { Filters } from 'src/view/components/Filters';
import { useConfigs } from 'src/hooks/useConfigs';


const CalendarFilters = () => {
  const { language } = useConfigs();
  const { filters } = useSearchParamsState();
  const { calendarAdditionalFilters, isCalendarFiltersLoading } = useCalendarFiltersState();
  const { getCalendarFilters } = useCalendarFiltersDispatch(language);
  const { setSearchParams } = useSearchParamsDispatch();

  return (
    <Filters
      isLoading={isCalendarFiltersLoading}
      activeFilters={filters}
      getFilters={getCalendarFilters}
      additionalFilters={calendarAdditionalFilters}
      setSearchParams={setSearchParams}
    />
  );
};

export { CalendarFilters };
