import React from 'react';
import BEM from 'bem-class-names-builder';
import { Space, Tooltip, Avatar, Comment } from 'antd';
import moment from 'moment';
import './styles.less';


const bem = new BEM('comment');

/**
 * @typedef Owner
 * @type {Object}
 * @property {string} firstname - owner first name
 * @property {string} lastname - owner last name
 */

/**
 * @typedef SessionComment
 * @type {Object}
 * @property {string} content - comment text
 * @property {string} createdDate - create datetime of comment in ISO 8601 format
 * @property {string} nameAffix - custom name of comment writer
 * @property {Array.<Owner>} owners - creator (owner) of comment
 */

/**
 * Component for showing the list of comments in particular session
 *
 * @category Main/Components
 *
 * @param {object} props
 * @param {Array.<SessionComment>} props.sessionComments - list of session comments
 * @param {number} props.totalCount - total count of session comments
 */
const CommentsBox = ({ sessionComments = [], totalCount }) => {
  const placeholder = (
    <div className={bem.elem('placeholder').toString()}>
      <div>
        <strong>There are no comments yet.</strong>
      </div>
      <div>You are welcome to give answers, ask questions, or start a discussion for this session.</div>
    </div>
  );

  return (
    <div className={bem.elem('container').toString()}>
      {totalCount === 0
        ? placeholder
        : sessionComments.map(({ _id, content, createdDate, nameAffix, owners: [{ firstname = 'Unknown firstname', lastname = 'Unknown lastname' }] }, i) => {
          const affix = Object.hasOwn(sessionComments[i], 'nameAffix')
            ? (nameAffix ? `(${nameAffix})` : '(Chair)')
            : '';

          return (
            <Comment
              key={_id}
              className={bem.elem('item').toString()}
              author={(firstname || lastname) && (<Space>{`${firstname} ${lastname} ${affix}`}</Space>)}
              avatar={<Avatar style={{ backgroundColor: '#f56a00' }}>{firstname && firstname.toUpperCase()[0]}{lastname && lastname.toUpperCase()[0]}</Avatar>}
              content={<p>{content}</p>}
              datetime={
                <Tooltip title={moment(createdDate).format('YYYY-MM-DD HH:mm:ss')}>
                  <span>{moment(createdDate).fromNow()}</span>
                </Tooltip>
              }
            />
          );
        })}
    </div>);
};

export { CommentsBox };
