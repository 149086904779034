import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { config } from 'src/configs';
import { App } from './view/App';
import 'moment/locale/en-gb';
import 'moment/locale/de';


const rootElement = document.getElementById('online-program');
const languages = ['en-US', 'de-DE'];

const urlParams = new URLSearchParams(window.location.search);

const initialLanguage = rootElement.getAttribute('data-language') || urlParams.get('language');
const scope = rootElement.getAttribute('data-scope') || urlParams.get('scope');

const defaultLanguage = languages.includes(initialLanguage) ? initialLanguage : languages[0];

const setLocale = language => {
  switch (language) {
    case 'de-DE':
      moment.locale('de');
      break;
    default:
      moment.locale('en-gb');
  }
};

setLocale(defaultLanguage);

const i18nextConfig = {
  lng: defaultLanguage,
  supportedLngs: languages,
  preload: languages,
  ns: ['translation'],
  defaultNS: 'translation',
  backend: {
    loadPath: `${config.api.services.rosetta}/${scope}/{{ns}}/_export_i18next_json/{{lng}}`,
    request: (options, url, payload, callback) => {
      // TODO: should be replaced by ROSETTA service call (it already exists, but has an issue with cross-fetch)
      axios.get(url)
        .then(res => callback(null, res))
        .catch(err => callback(err));
    },
  },
};

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init(i18nextConfig)
  .then(() => (
    ReactDOM.render(
      <React.StrictMode>
        <App/>
      </React.StrictMode>,
      rootElement,
    )
  ));
