import { createActions } from 'src/modules/createActions';


const sessionAdvertisementsActions = createActions('session-advertisements', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);

export { sessionAdvertisementsActions };
