import { createActions } from 'src/modules/createActions';


const personItemActions = createActions('personItem', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
  'UPDATE_PERSON',
]);

export { personItemActions };
