import { useSelector, useDispatch } from 'react-redux';
import { sessionListActions } from 'src/store/sessions/list/actions';
import {
  selectSessionList,
  selectSessionTotalCount,
  selectSessionListLoading,
} from 'src/store/sessions/list/selectors';


const useSessionListState = () => ({
  sessionList: useSelector(selectSessionList),
  sessionTotalCount: useSelector(selectSessionTotalCount),
  isSessionListLoading: useSelector(selectSessionListLoading),
});

const useSessionListDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSessionsList: data => dispatch(sessionListActions.fetch(data)),
  });
};

export { useSessionListState, useSessionListDispatch };
