import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { speakerAdvertisementsActions } from 'src/store/speakers/advertisements/actions';


const speakerAdvertisementsReducer = combineReducers({
  entries: createReducer([], {
    [speakerAdvertisementsActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [speakerAdvertisementsActions.fetch.type],
      stop: [speakerAdvertisementsActions.fetchSucceeded.type, speakerAdvertisementsActions.fetchFailed.type],
    },
  ]),
});

export { speakerAdvertisementsReducer };
