import React from 'react';
import { useConfigs } from 'src/hooks/useConfigs';
import { useSessionFiltersState, useSessionFiltersDispatch } from 'src/store/sessions/filters/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { Filters } from 'src/view/components/Filters';
import './styles.less';


const SessionFilters = () => {
  const { language } = useConfigs();
  const { filters } = useSearchParamsState();
  const { sessionAdditionalFilters, isSessionFiltersLoading } = useSessionFiltersState();
  const { getSessionFilters } = useSessionFiltersDispatch(language);
  const { setSearchParams } = useSearchParamsDispatch();

  return (
    <Filters
      isLoading={isSessionFiltersLoading}
      activeFilters={filters}
      getFilters={getSessionFilters}
      additionalFilters={sessionAdditionalFilters}
      setSearchParams={setSearchParams}
    />
  );
};

export { SessionFilters };
