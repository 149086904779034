import { createFilusFileService } from '@globit/api-services';
import { config } from 'src/configs';


const createFileService = (proxy, scope) => {
  const fileService = createFilusFileService(config.api.services.filus, scope, proxy);

  return {
    downloadFile: _file => fileService.download(_file),
  };
};

export { createFileService };
