import { useSelector, useDispatch } from 'react-redux';
import { sessionItemActions } from 'src/store/sessions/item/actions';
import {
  selectLiveStream,
  selectOnDemand,
  selectIsRecordingsExist,
  selectSession,
  selectSessionId,
  selectSessionItem,
  selectLastRecording,
  selectSessionItemLoading,
} from 'src/store/sessions/item/selectors';


const useSessionItemState = () => ({
  sessionId: useSelector(selectSessionId),
  sessionItem: useSelector(selectSessionItem),
  session: useSelector(selectSession),
  liveStream: useSelector(selectLiveStream),
  isRecordingsExist: useSelector(selectIsRecordingsExist),
  lastRecording: useSelector(selectLastRecording),
  onDemand: useSelector(selectOnDemand),
  isSessionItemLoading: useSelector(selectSessionItemLoading),
});

const useSessionItemDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSessionItem: data => dispatch(sessionItemActions.fetch(data)),
  });
};

export { useSessionItemState, useSessionItemDispatch };
