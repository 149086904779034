import Cookies from 'cookies-js';
import { createBasicAuthProxy } from '@globit/fetcher';
import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createAuthenticationService } from 'src/api/authenticationService';
import { config } from 'src/configs';
import { accessTokenActions } from 'src/store/access/token/actions';
import { accessStatusActions } from 'src/store/access/status/actions';
import { accessRolesActions } from 'src/store/access/roles/actions';
import { accessRefActions } from 'src/store/access/ref/actions';
import { routes } from 'src/routes';
import { getAccessOwner, getAccessRoles } from 'src/store/access/utils';
import { TOKEN, ACCESS_EXPIRED_OR_INVALID, ACCESS_ACCOUNT, ACCESS_CLIENT } from 'src/constants/access';
import { selectAccessStatus } from 'src/store/access/selectors';


const proxy = createBasicAuthProxy(config.api.clientId, config.api.clientSecret);
const authenticationService = createAuthenticationService(proxy);

function* setAccountToken({ payload }) {
  const { values, reject } = payload;
  const { router } = yield select();

  const redirectTo = router.location.redirectedFrom || routes.root;

  try {
    const account = yield call(authenticationService.authenticateAccount, values);

    yield put(accessTokenActions.setTokenSucceeded(account.access_token));
    yield call(Cookies.set, TOKEN, account.access_token);
    yield put(accessStatusActions.set(ACCESS_ACCOUNT));
    yield put(push(redirectTo));
  } catch (error) {
    yield call(reject, error);
  }
}

function* setClientToken() {
  try {
    const client = yield call(authenticationService.authenticateClient);

    yield put(accessStatusActions.set(ACCESS_CLIENT));
    yield put(accessTokenActions.setTokenSucceeded(client.access_token));
    yield call(Cookies.set, TOKEN, client.access_token);
  } catch (e) {
    // TODO: add notification for error
    yield put(accessTokenActions.setTokenFailed(e));
  }
}

function* checkToken({ payload }) {
  try {
    const access = yield call(authenticationService.tokenCheck, payload);

    yield put(accessTokenActions.setTokenSucceeded(access.access_token));
    yield put(accessRefActions.set(access?._owner?.$ref));
    yield put(accessStatusActions.set(getAccessOwner(access)));
    yield put(accessRolesActions.set(getAccessRoles(access)));
  } catch (e) {
    yield put(accessTokenActions.setClientToken());
  }
}

function* refreshToken() {
  const accessStatus = yield select(selectAccessStatus);

  yield put(accessStatusActions.set(ACCESS_EXPIRED_OR_INVALID));
  yield put(accessTokenActions.clean());

  if (accessStatus === ACCESS_ACCOUNT) {
    yield put(push(routes.login));
  } else {
    yield put(accessTokenActions.setClientToken());
  }
}

export { checkToken, setClientToken, setAccountToken, refreshToken };
