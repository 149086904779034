import { call, put, select } from 'redux-saga/effects';
import { createCountryService } from 'src/api/countryService';
import { countryListActions } from 'src/store/countries/list/actions';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';
// eslint-disable-next-line import/no-cycle
import { store } from 'src/store/store';


function* fetchCountries() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const countryService = createCountryService(authProxy, scope);

  try {
    const countries = yield call(countryService.listAll);

    yield put(countryListActions.fetchSucceeded(countries.items));
  } catch (e) {
    yield put(countryListActions.fetchFailed(e.message));
  }
}

export { fetchCountries };
