import { createActions } from 'src/modules/createActions';


const sessionItemActions = createActions('session-item', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);

export { sessionItemActions };
