import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { ResetIcon } from 'src/view/components/FilterBox/components/ResetIcon';
import './styles.less';


const ClearFilters = ({ searchValue, setSearchValue }) => {
  const { t } = useTranslation();
  const { filters, favorites } = useSearchParamsState();
  const { setSearchParams } = useSearchParamsDispatch();

  return (
    <Button
      className='clear-button'
      disabled={!(searchValue || filters || favorites)}
      onClick={() => {
        setSearchParams({ filters: {}, search: '', favorites: null });
        setSearchValue('');
      }}
    >
      <ResetIcon className='reset-icon'/>
      {t('clearFilters')}
    </Button>
  );
};

export { ClearFilters };
