import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { speakerItemActions } from 'src/store/speakers/item/actions';


const speakerItemReducer = combineReducers({
  entry: createReducer(null, {
    [speakerItemActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [speakerItemActions.fetch.type],
      stop: [speakerItemActions.fetchSucceeded.type, speakerItemActions.fetchFailed.type],
    },
  ]),
});

export { speakerItemReducer };
