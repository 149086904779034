import React, { useEffect } from 'react';
import { useSessionItemState, useSessionItemDispatch } from 'src/store/sessions/item/hooks';
import { SessionItem } from 'src/view/pages/Sessions/Item';
import { Layout } from 'src/view/components/Layout';
import { Container } from 'src/view/components/Container';
import { VIEW_TYPE_OVERVIEW } from 'src/constants/session';
import { useConfigs } from 'src/hooks/useConfigs';


const SessionOverview = () => {
  const { sessionId } = useConfigs();
  const { sessionItem } = useSessionItemState();
  const { getSessionItem } = useSessionItemDispatch();
  const data = sessionItem && (sessionItem?.parent || sessionItem?.session);

  useEffect(() => {
    getSessionItem(sessionId);
  }, []);

  return data && (
    <Layout>
      <Container>
        <SessionItem sessionViewType={VIEW_TYPE_OVERVIEW} {...data} />
      </Container>
    </Layout>
  );
};

export { SessionOverview };
