import { createReducer } from '@reduxjs/toolkit';
import { accessTokenActions } from 'src/store/access/token/actions';


const accessTokenReducer = createReducer('', {
  [accessTokenActions.setTokenSucceeded]: (state, action) => action.payload,
  [accessTokenActions.clean]: () => '',
});

export { accessTokenReducer };
