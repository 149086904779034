import { call, put, select } from 'redux-saga/effects';
import { createAuthProxy } from 'src/store/access/utils';
import { sessionCommentsListActions } from 'src/store/sessionComments/list/actions';
import { store } from 'src/store/store';
import { selectScope } from 'src/store/scope/selectors';
import { createSessionCommentService } from 'src/api/sessionCommentService';
import { createSessionService } from 'src/api/sessionService';


function* fetchSessionCommentsList({ payload: sessionId }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const sessionCommentService = createSessionCommentService(authProxy, scope);
  const sessionService = createSessionService(authProxy, scope);

  try {
    const sessionComments = yield call(sessionCommentService.search, {
      query: { and: [
        { or: [{ status: 'accepted' }, { status: 'answered' }] },
        { eq: { session: { $ref: sessionService.getHref(sessionId) } } },
        { empty: { content: false } },
      ] },
      sort: [['createdDate', 'desc']],
    });

    yield put(sessionCommentsListActions.fetchSucceeded(sessionComments.items));
    yield put(sessionCommentsListActions.setTotalCount(sessionComments.totalCount));
  } catch (e) {
    yield put(sessionCommentsListActions.fetchFailed(e.message));
  }
}


export { fetchSessionCommentsList };
