import React, { useState, useEffect, Fragment, useContext } from 'react';
import BEM from 'bem-class-names-builder';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'src/view/components/Spinner';
import { getTimePeriod } from 'src/utils/date';
import { TimezoneContext } from 'src/contexts/TimezoneContext';
import { useInitState } from 'src/store/init/hooks';
import { TimezoneSwitcher } from '../TimezoneSwitcher';
import { Event } from './components/Event';
import { TimeMarker } from './components/TimeMarker';
import { TimeBlocks } from './components/TimeBlocks';
import { DaySelection } from './components/DaySelection';
import './styles.less';


const bem = new BEM('op-day-schedule');
const DEFAULT_VISIBLE_COLUMNS = 4;

const DaySchedule = ({ isLoading, selectedDate, rooms, adjustTime, sessions, timeStart, timeEnd, timelineItemSize,
  timeFormat, calendarView }) => {
  const { t } = useTranslation();
  const { onlineprogram: { calendarView: { sidewayScrolling } = {} } = {} } = useInitState();

  const { optionalTimeZones } = useContext(TimezoneContext);
  const [scrollOffsetCount, setScrollOffsetCount] = useState(0);

  const scrollOffsetX = (100 / DEFAULT_VISIBLE_COLUMNS) * scrollOffsetCount;

  const sessionsRoom = sessions.map(({ room }) => room?.name);
  const availableRooms = rooms.filter(room => sessionsRoom.includes(room));

  const maxScrollOffsetCount = availableRooms.length && availableRooms.length > DEFAULT_VISIBLE_COLUMNS
    ? availableRooms.length - DEFAULT_VISIBLE_COLUMNS
    : 0;

  const hoursRange = getTimePeriod(timeStart, timeEnd, selectedDate);

  const { timeBlocks } = calendarView || {};

  useEffect(() => {
    if (maxScrollOffsetCount < scrollOffsetCount) {
      setScrollOffsetCount(maxScrollOffsetCount);
    }
  }, [maxScrollOffsetCount]);

  const handlePrevOffset = () => {
    if (scrollOffsetCount > 0) {
      setScrollOffsetCount(scrollOffsetCount - 1);
    }
  };

  const handleNextOffset = () => {
    if (scrollOffsetCount < maxScrollOffsetCount) {
      setScrollOffsetCount(scrollOffsetCount + 1);
    }
  };

  return (
    <div className={bem}>
      <Spinner isLoading={isLoading}>
        <div className={bem.elem('op-selected-day')}>
          <DaySelection />
          {!!optionalTimeZones.length && <TimezoneSwitcher onChange={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />}
        </div>
        {!sidewayScrolling && (<div className={bem.elem('op-table-header')}>
          {!!availableRooms.length && (
            <Fragment>
              <LeftCircleFilled
                className={bem.elem('op-scroll-button').mods('left').toString()}
                onClick={handlePrevOffset}
              />
              <div className={bem.elem('op-header-time-slot')} />
              <div className={bem.elem('op-header-rooms')} style={{ transform: `translate3d(-${scrollOffsetX}%, 0, 0)` }}>
                {
                  availableRooms.map((room, i) => (
                    <div className={bem.elem('op-header-room')} key={i}>
                      { room }
                    </div>
                  ))
                }
              </div>
              <RightCircleFilled
                className={bem.elem('op-scroll-button').mods('right').toString()}
                onClick={handleNextOffset}
              />
            </Fragment>
          )}
          {!availableRooms.length && (
            <div className={bem.elem('op-no-sessions')}>
              <span>{t('noSessionToday')}</span>
            </div>
          )}
        </div>)}
        {!!availableRooms.length && (
          <div className={bem.elem('op-table-body')} style={{
            maxHeight: timeBlocks?.active ? 'calc(100vh - 250px)' : 'initial',
            overflow: timeBlocks?.active ? 'scroll' : 'initial',
          }}>
            <div className={bem.elem('op-table')}>
              <TimeMarker
                adjustTime={adjustTime}
                hoursRangeLength={hoursRange.length}
                timelineStart={timeStart}
                timelineEnd={timeEnd}
                timelineItemSize={timelineItemSize}
                timeFormat={timeFormat}
              />
              {!timeBlocks?.active && (<div
                className={bem.elem('op-timeline')}
                style={{ paddingTop: sidewayScrolling ? '102px' : '0' }}
              >
                {hoursRange.map(time => (
                  <div
                    key={time}
                    className={bem.elem('op-time-slot')}
                    style={{ height: `${timelineItemSize}px` }}
                  >
                    {time.format(timeFormat)}
                  </div>
                ))}
              </div>)}
              <div className={bem.elem('op-events')} style={{
                overflowX: (sidewayScrolling && !timeBlocks?.active) ? 'scroll' : 'hidden',
                transform: (sidewayScrolling && !timeBlocks?.active) ? 'rotateX(180deg)' : 'initial',
              }}
              >
                <div
                  style={{
                    transform: (sidewayScrolling && !timeBlocks?.active) ? 'rotateX(180deg)'
                      : timeBlocks?.active ? 0 : 'initial',
                    flexDirection: (timeBlocks?.active || sidewayScrolling) ? 'column' : 'row',
                    display: 'flex',
                  }}
                >
                  {(sidewayScrolling && !timeBlocks?.active) && (<div className={bem.elem('op-events-rooms')}>
                    {!!availableRooms.length && (
                      <Fragment>
                        <div className={bem.elem('op-header-rooms')}>
                          {
                            availableRooms.map((room, i) => (
                              <div className={bem.elem('op-header-room')} key={i}>
                                { room }
                              </div>
                            ))
                          }
                        </div>
                      </Fragment>
                    )}
                    {!availableRooms.length && (
                      <div className={bem.elem('op-no-sessions')}>
                        <span>{t('noSessionToday')}</span>
                      </div>
                    )}
                    </div>)}
                  {timeBlocks?.active ? (
                    <TimeBlocks
                      timeBlocks={timeBlocks}
                      hoursRange={hoursRange}
                      availableRooms={availableRooms}
                      selectedDate={selectedDate}
                      hoursRangeLength={hoursRange.length}
                      timelineItemSize={timelineItemSize}
                      timeStart={timeStart}
                      timeEnd={timeEnd}
                      timeFormat={timeFormat}
                      sessions={sessions}
                      scrollOffsetX={scrollOffsetX}
                      sidewayScrolling={sidewayScrolling}
                    />) : (
                      <div
                          className={bem.elem('op-events-container')}
                          style={{ transform: `translate3d(-${scrollOffsetX}%, 0, 0)` }}
                        >{availableRooms.map(room => (
                        <div key={room} className={bem.elem('op-event-item')}>
                          {hoursRange.map(hour => (
                            <div
                              className={bem.elem('op-time-item')}
                              key={hour}
                              style={{ height: `${timelineItemSize}px` }}
                            />
                          ))}
                          {sessions
                            .filter(({ room: sessionRoom }) => sessionRoom?.name === room)
                            .map(session => (
                              <Event
                                selectedDate={selectedDate}
                                hoursRangeLength={hoursRange.length}
                                timelineItemSize={timelineItemSize}
                                timelineStart={timeStart}
                                timelineEnd={timeEnd}
                                timeFormat={timeFormat}
                                key={session.public_number}
                                {...session}
                              />
                            ))}
                        </div>))
                    }</div>)}
                </div>
              </div>
            </div>
          </div>
        )}
      </Spinner>
    </div>
  );
};

export { DaySchedule };
