const isFilterActive = (currentFilter, filters) =>
  (Array.isArray(filters)
    ? filters.map(i => JSON.stringify(i)).includes(JSON.stringify(currentFilter))
    : JSON.stringify(currentFilter) === JSON.stringify(filters));


const getDefaultFilters = filters => filters.reduce((acc, filter) => {
  const { isDefault, name, operator, mode, nested, items } = filter;
  const defaultItems = isDefault ? items : items.filter(item => item.isDefault);

  return defaultItems.length ? {
    ...acc,
    [name]: { operator, mode, nested, items: defaultItems },
  } : acc;
}, {});

export { isFilterActive, getDefaultFilters };
