import { createActions } from 'src/modules/createActions';


const speakerListActions = createActions('speaker-list', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
  'SET_TOTAL_COUNT',
]);


export { speakerListActions };
