import { useSelector, useDispatch } from 'react-redux';
import { sessionAdvertisementsActions } from 'src/store/sessions/advertisements/actions';
import {
  selectSessionAdvertisements,
  selectSessionAdvertisementsLoading,
} from 'src/store/sessions/advertisements/selectors';


const useSessionAdvertisementsState = () => ({
  sessionAdvertisements: useSelector(selectSessionAdvertisements),
  isSessionAdvertisementsLoading: useSelector(selectSessionAdvertisementsLoading),
});

const useSessionAdvertisementsDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSessionAdvertisements: () => dispatch(sessionAdvertisementsActions.fetch()),
  });
};

export { useSessionAdvertisementsState, useSessionAdvertisementsDispatch };
