import { createSelmaEventService } from '@globit/api-services';
import { config } from '../configs';


const createDispatchService = (proxy, scope) => {
  const selmaService = createSelmaEventService(config.api.services.selma, scope, proxy);

  return {
    init: () => selmaService.dispatchByName('op-init'),
    getSessionList: data => selmaService.dispatchByName('op-sessions', data),
    getSessionFilters: data => selmaService.dispatchByName('op-session-filters', data),
    getSpeakerList: data => selmaService.dispatchByName('op-speakers', data),
    getSessionItem: id => selmaService.dispatchByName('op-session-detail', { id }),
    getSpeakerItem: id => selmaService.dispatchByName('op-speaker-detail', { id }),
    getSpeakerFilters: data => selmaService.dispatchByName('op-speaker-filters', data),
    getSessionAdvertisements: data => selmaService.dispatchByName('op-session-advertisements', data),
    getSpeakerAdvertisements: data => selmaService.dispatchByName('op-speaker-advertisements', data),
  };
};

export { createDispatchService };
