import { useSelector, useDispatch } from 'react-redux';
import { sessionFavoritesActions } from 'src/store/sessions/favorites/actions';
import { selectSessionFavorites, selectSessionFavoritesLoading } from 'src/store/sessions/favorites/selectors';


const useSessionFavoritesState = () => ({
  sessionFavorites: useSelector(selectSessionFavorites),
  isSessionFavoritesLoading: useSelector(selectSessionFavoritesLoading),
});

const useSessionFavoritesDispatch = () => {
  const dispatch = useDispatch();

  return ({
    fetchFavorites: () => dispatch(sessionFavoritesActions.fetch()),
    addSessionToFavorites: legacyId => dispatch(sessionFavoritesActions.add(legacyId)),
    removeSessionFromFavorites: legacyId => dispatch(sessionFavoritesActions.remove(legacyId)),
  });
};

export { useSessionFavoritesState, useSessionFavoritesDispatch };
