import { call, put, select } from 'redux-saga/effects';
import { createBasicAuthProxy } from '@globit/fetcher';
import { createAuthProxy } from 'src/store/access/utils';
import { sessionCommentsItemActions } from 'src/store/sessionComments/item/actions';
import { store } from 'src/store/store';
import { selectScope } from 'src/store/scope/selectors';
import { selectAccessToken } from 'src/store/access/selectors';
import { createSessionCommentService } from 'src/api/sessionCommentService';
import { createAuthenticationService } from 'src/api/authenticationService';
import { createSessionService } from 'src/api/sessionService';
import { config } from 'src/configs';


function* createSessionComment({ payload: { sessionId, ...sessionComment } }) {
  const authProxy = createAuthProxy(store);
  const clientProxy = createBasicAuthProxy(config.api.clientId, config.api.clientSecret);
  const scope = yield select(selectScope);
  const token = yield select(selectAccessToken);
  const sessionCommentService = createSessionCommentService(authProxy, scope);
  const sessionService = createSessionService(authProxy, scope);
  const authenticationService = createAuthenticationService(clientProxy);

  try {
    const account = yield call(authenticationService.tokenCheck, token);
    const newComment = yield call(sessionCommentService.create, {
      ...sessionComment,
      owners: [{
        $ref: account?._owner?.$ref,
      }],
      session: { $ref: sessionService.getHref(sessionId) },
    });

    yield put(sessionCommentsItemActions.createSucceeded(newComment));
  } catch (e) {
    yield put(sessionCommentsItemActions.createFailed(e.message));
  }
}
export { createSessionComment };
