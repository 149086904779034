import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import PropTypes from 'prop-types';
import BEM from 'bem-class-names-builder';
import { pageSizeOptions } from 'src/constants/pagination';
import './styles.less';


const bem = new BEM('op-pagination');

const Pagination = ({ totalCount, onChange, limit, offset }) => {
  const handleChange = (page, selectedLimit) => {
    onChange({ limit: selectedLimit, offset: selectedLimit * (page - 1) });
    const body = document.querySelector('#online-program');

    body.scrollIntoView({ behavior: 'auto' });
  };

  const currentPage = Math.floor(offset / limit) + 1;

  return (
    <AntdPagination
      className={bem.toString()}
      total={totalCount}
      pageSize={limit}
      pageSizeOptions={pageSizeOptions}
      showSizeChanger={true}
      current={currentPage}
      onChange={handleChange}
    />
  );
};

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number,
};

Pagination.defaultProps = {
  offset: 0,
};


export { Pagination };
