import { useDispatch } from 'react-redux';
import { sessionWatchTimeActions } from 'src/store/sessionWatchTime/actions';


const useSessionWatchTimeDispatch = () => {
  const dispatch = useDispatch();

  return ({
    createSessionWatchTime: data => dispatch(sessionWatchTimeActions.create(data)),
  });
};

export { useSessionWatchTimeDispatch };
