import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BEM from 'bem-class-names-builder';
import { Menu, Dropdown, Button } from 'antd';
import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useInitState } from 'src/store/init/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { getFormattedDay, getFormattedDate, getDaysRange, getActiveDay } from 'src/utils/date';
import { Spinner } from 'src/view/components/Spinner';
import { useSessionListState } from 'src/store/sessions/list/hooks';
import './styles.less';


const bem = new BEM('op-day-selection');

const DaySelection = () => {
  const { t } = useTranslation();
  const { congressStartDay, congressEndDay, congressDateFormat, isInitLoading } = useInitState();
  const { activeDay, search } = useSearchParamsState();
  const { setSearchParams } = useSearchParamsDispatch();
  const [index, setIndex] = useState(-1);
  const { sessionList } = useSessionListState();
  const daysRange = getDaysRange(congressStartDay, congressEndDay);
  const [currentSearch, setCurrentSearch] = useState('');
  const currentDay = daysRange[index];
  const handleSelectDay = ind => {
    setIndex(ind);
    setSearchParams({ activeDay: daysRange[ind], congressStartDay, congressEndDay });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const getDayLabel = day => day && `${getFormattedDate(day, congressDateFormat?.short)} | ${t(getFormattedDay(day))}`;

  useEffect(() => {
    if (congressStartDay && congressEndDay) {
      const ind = getActiveDay(congressStartDay, congressEndDay, activeDay);

      handleSelectDay(ind);
    }
  }, [congressStartDay, congressEndDay, activeDay]);

  useEffect(() => {
    if (search && sessionList.length > 0) {
      setCurrentSearch(search);

      const transformStartDateTimeToMoment = sessionList.map(({ start_date_time }) => moment(start_date_time));
      const earliestSession = moment.min(transformStartDateTimeToMoment);
      const ind = getActiveDay(congressStartDay, congressEndDay, earliestSession);

      if (currentSearch !== search || ind !== index) {
        handleSelectDay(ind);
      }
    }
  }, [search, sessionList.length]);

  return (
    <Spinner isLoading={isInitLoading}>
      <div className={bem}>
        <span className={bem.elem('caret')}>
          {!!index && <LeftOutlined onClick={() => handleSelectDay(index - 1)} />}
        </span>
        <span className={bem.elem('label')}>
          {getDayLabel(currentDay)}
        </span>
        <span className={bem.elem('caret')}>
          {index !== daysRange.length - 1 && <RightOutlined onClick={() => handleSelectDay(index + 1)}/>}
        </span>
        <Dropdown overlay={
          <Menu>
            {daysRange.map((day, i) => (
              <Menu.Item key={i} onClick={() => handleSelectDay(i)}>
                {getDayLabel(day)}
              </Menu.Item>
            ))}
            </Menu>
          }>
            <Button><CalendarOutlined style={{ fontSize: '16px' }}/></Button>
          </Dropdown>
      </div>
    </Spinner>
  );
};

export { DaySelection };
