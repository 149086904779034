import moment from 'moment';
import { CONGRESS_TYPE_ONLINE } from 'src/constants/congress';
import { CALENDATE_DATE_TIME_FORMAT } from 'src/constants/calendar';


const getSessionConfig = (congress = {}, session = {}) => {
  const { congressType, description } = congress;
  const { title, room = {}, playerUrl, public_number, session_type, topics,
    start_date_time, end_date_time, utc_start_time, utc_end_time } = session;
  const { name } = room;

  const addNewLineToText = text => (text ? `${text},\n` : '');

  const location = congressType === CONGRESS_TYPE_ONLINE ? playerUrl : name;
  const summary = public_number ? `${public_number} - ${title}` : title;

  const topicsList = topics && topics.map(item => item.name).join(',\n');
  const sessionTypeName = session_type && session_type.name;
  const watchLive = playerUrl && `Watch live/on-demand: ${playerUrl}`;

  const start_time = utc_start_time || start_date_time;
  const end_time = utc_end_time || end_date_time;
  const startDateTime = start_time && moment(start_time).utc().format(CALENDATE_DATE_TIME_FORMAT);
  const endDateTime = end_time && moment(end_time).utc().format(CALENDATE_DATE_TIME_FORMAT);

  return ({
    location: location || '',
    summary: summary || '',
    title: addNewLineToText(summary),
    description: addNewLineToText(description),
    sessionTypeName: addNewLineToText(sessionTypeName),
    topicsList: addNewLineToText(topicsList),
    watchLive: watchLive || '',
    startDateTime: startDateTime || '',
    endDateTime: endDateTime || '',
  });
};

const getCalendarConfig = config => `
BEGIN:VCALENDAR
VERSION:2.0
METHOD:PUBLISH
BEGIN:VEVENT
DTSTART:${config.startDateTime}Z
DTEND:${config.endDateTime}Z
DESCRIPTION:${config.description} ${config.title} ${config.sessionTypeName} ${config.topicsList} ${config.watchLive}
LOCATION:${config.location}
SEQUENCE:0
SUMMARY:${config.summary} 
TRANSP:OPAQUE
END:VEVENT
END:VCALENDAR`;

const getSessionContributionSelector = index =>
  `.session-contributions .contribution-item:nth-child(${index + 1}) .session-contribution-body`;

const getContributionAbstractContentUrl = (contributions, contributionAbstractId) => {
  const contribution = contributions && contributions.find(item => item?.abstract?._id === contributionAbstractId);

  return contribution?.abstract?.content_url;
};

export { getSessionConfig, getCalendarConfig, getSessionContributionSelector, getContributionAbstractContentUrl };
