import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { initActions } from 'src/store/init/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';


function* fetchInit() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const init = yield call(selmaService.init);

    yield put(initActions.fetchSucceeded(init));
  } catch (e) {
    yield put(initActions.fetchFailed(e.message));
  }
}

export { fetchInit };
