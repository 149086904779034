import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BEM from 'bem-class-names-builder';
import { Button, Input } from 'antd';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import './styles.less';


const bem = new BEM('op-search-box');

const SearchBox = ({ favoriteEntities = [], searchValue, setSearchValue }) => {
  const { t } = useTranslation();
  const { accessStatus, accessRoles } = useAccessState();
  const { favorites } = useSearchParamsState();
  const { setSearchParams: onChange } = useSearchParamsDispatch();

  return (
    <div className={bem.toString()}>
      <Input.Search
        className={bem.elem('input').toString()}
        placeholder={t('searchRequest')}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onSearch={() => onChange({ search: searchValue, offset: 0 })}
        enterButton={<Button
          type="primary"
          icon={<SearchOutlined />}
          className={bem.elem('button').toString()}
        />}
      />
      {accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT) && (
        <div className={bem.elem('favorites-box').toString()}>
          <Button onClick={() =>
            onChange({
              favorites: favorites ? null : favoriteEntities,
              offset: 0,
            })}
          className={bem.elem('favorites').mods(favorites?.length && 'active').toString()}
          disabled={!favoriteEntities.length}
          >
            <StarFilled style={{ color: '#ffa64d' }} />
            <span>{t('favorites')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  isFilterDisabled: PropTypes.bool,
  search: PropTypes.string,
  onChange: PropTypes.func,
};

SearchBox.defaultProps = {
  isFilterDisabled: false,
  search: '',
};


export { SearchBox };
