import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { speakerFavoritesActions } from 'src/store/speakers/favorites/actions';
import { sessionFavoritesActions } from 'src/store/sessions/favorites/actions';
import { createLoadingReducer } from 'src/modules/loadingReducer';


const speakerFavoritesReducer = combineReducers({
  entries: createReducer([], {
    [speakerFavoritesActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [speakerFavoritesActions.fetch.type],
      stop: [speakerFavoritesActions.fetchSucceeded.type, sessionFavoritesActions.fetchFailed.type],
    },
    {
      start: [speakerFavoritesActions.add.type],
      stop: [speakerFavoritesActions.addSucceeded.type, speakerFavoritesActions.addFailed.type],
    },
    {
      start: [speakerFavoritesActions.remove.type],
      stop: [speakerFavoritesActions.removeSucceeded.type, speakerFavoritesActions.removeFailed.type],
    },
  ]),
});

export { speakerFavoritesReducer };
