import { createSelector } from 'reselect';
import { NAVIGATION_FILTER_TYPE } from 'src/constants/filters';


const selectSpeakerFilters = state => state.speaker.filters.entries;
const selectSpeakerFiltersLoading = state => state.speaker.filters.loading > 0;

const selectSpeakerNavigationFilters = createSelector(
  state => selectSpeakerFilters(state),
  filters => (filters || []).filter(item => item.view === NAVIGATION_FILTER_TYPE),
);

const selectSpeakerAdditionalFilters = createSelector(
  state => selectSpeakerFilters(state),
  filters => (filters || []).filter(item => item.view !== NAVIGATION_FILTER_TYPE),
);

export {
  selectSpeakerFilters,
  selectSpeakerFiltersLoading,
  selectSpeakerNavigationFilters,
  selectSpeakerAdditionalFilters,
};
