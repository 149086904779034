import { combineReducers } from 'redux';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { fileActions } from 'src/store/files/actions';


const filesReducer = combineReducers({
  loading: createLoadingReducer([
    {
      start: [fileActions.download.type],
      stop: [fileActions.downloadSucceeded.type, fileActions.downloadFailed.type],
    },
  ]),
});

export { filesReducer };

