import { useEffect } from 'react';
import { useCountryListDispatch } from 'src/store/countries/list/hooks';


const CountriesProvider = ({ children }) => {
  const { fetchCountryList } = useCountryListDispatch();

  useEffect(() => {
    fetchCountryList();
  }, []);

  return children;
};

export { CountriesProvider };
