const getCountryName = (list = [], code = []) => {
  const isCountryCodeExist = code.length > 0 && code[0]?.data;
  const countryCode = isCountryCodeExist && code[0]?.data.trim();

  const country = list.find(({ alpha_3_code }) => alpha_3_code === countryCode);
  const countryName = country ? country?.name : countryCode;

  return countryName;
};

export { getCountryName };
