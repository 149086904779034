import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Select, Radio } from 'antd';
import BEM from 'bem-class-names-builder';
import {
  RADIO_FILTER_TYPE,
  CHECKBOX_FILTER_TYPE,
  SELECT_FILTER_TYPE,
  PREFIX_LIST_FILTER_TYPE,
} from 'src/constants/filters';
import { isFilterActive } from 'src/utils/filter';
import { filterItemShape } from 'src/view/propTypes/filter';
import { ResetIcon } from 'src/view/components/FilterBox/components/ResetIcon';
import { Popover } from './components/Popover';
import { PrefixListFilter } from './components/PrefixListFilter';
import './styles.less';


const bem = new BEM('op-filter-box');

const FilterBox = ({ title, view, operator, mode, nested, items, style, activeFilter, onChange }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(true);
  const selectedItems = activeFilter ? items.filter(item => isFilterActive(item, activeFilter.items)) : [];
  const isAllSelected = selectedItems.length === items.length;

  const handleChange = value => onChange({ operator, mode, nested, items: Array.isArray(value) ? value : [value] });
  const handleSelectAll = () => onChange({ operator, mode, nested, items: isAllSelected ? [] : items });

  const filterRegistry = {
    [RADIO_FILTER_TYPE]: options => {
      const renderContent = (<Radio.Group
        onChange={e => handleChange(e.target.value)}
        value={selectedItems && selectedItems[0]}
      >
        {options.map(option =>
          <Radio
            value={option}
            key={option.label}
            className={bem.elem('item').toString()}
            style={{ ...style.item }}
          >
            <span className={bem.elem('item__label').toString()}>{option.label}</span>
            {selectedItems[0] === option && <ResetIcon
              className={bem.elem('reset-icon').toString()}
              style={{ ...style.resetIcon }}
              handleChange={handleChange}
            />}
          </Radio>,
        )}
      </Radio.Group>);

      return (
        <Popover
          renderContent={renderContent}
          style={style}
          bem={bem}
          setIsActive={setIsActive}
          isActive={isActive}
          title={title}
        />
      );
    },
    [CHECKBOX_FILTER_TYPE]: options => {
      const renderContent = (<React.Fragment>
        <Checkbox
          indeterminate={!!selectedItems.length && !isAllSelected}
          onChange={handleSelectAll}
          checked={isAllSelected}
          className={bem.elem('item').toString()}
          style={{ ...style.item }}
        >
          {t('selectAll')}
        </Checkbox>
        <Checkbox.Group
          value={selectedItems}
          onChange={handleChange}
        >
          {options.map(option => (
            <Checkbox
              key={option.label}
              value={option}
              className={bem.elem('item').toString()}
              style={{ ...style.item }}
            >
              {option.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </React.Fragment>);

      return (
        <Popover
          renderContent={renderContent}
          style={style}
          bem={bem}
          setIsActive={setIsActive}
          isActive={isActive}
          title={title}
        />
      );
    },
    [SELECT_FILTER_TYPE]: options => (
      <Select
        value={selectedItems.map(item => JSON.stringify(item))}
        className={bem.elem('item').toString()}
        style={{ ...style.item }}
        onChange={value => handleChange(JSON.parse(value))}
      >
        {options.map(option => (
          <Select.Option value={JSON.stringify(option)} key={option.label}>
            <span className={bem.elem('item__label').toString()}>{option.label}</span>
            {selectedItems[0] === option && <ResetIcon
              className={bem.elem('reset-icon').toString()}
              handleChange={handleChange}
              style={{ ...style.resetIcon }}
            />}
          </Select.Option>
        ))}
      </Select>
    ),
    [PREFIX_LIST_FILTER_TYPE]: options => (
      <PrefixListFilter
        options={options}
        style={style}
        value={selectedItems && selectedItems[0]}
        onChange={handleChange}
      />
    ),
  };
  const filterFactory = viewType => filterRegistry[viewType] || (() => new Error(`unknown view ${viewType}`));
  const filterView = filterFactory(view)(items);
  const isActiveFilter = selectedItems.length
    && items.some(item => selectedItems.map(({ label }) => label).includes(item.label));

  return (
    <div className={`${bem.toString()} ${bem.toString()}${isActiveFilter ? '--active' : ''}`} style={{ ...style.box }}>
        {filterView}
    </div>
  );
};


FilterBox.propTypes = {
  title: PropTypes.string,
  view: PropTypes.oneOf([
    RADIO_FILTER_TYPE,
    CHECKBOX_FILTER_TYPE,
    SELECT_FILTER_TYPE,
    PREFIX_LIST_FILTER_TYPE,
  ]),
  activeFilter: PropTypes.object,
  mode: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  nested: PropTypes.string,
  items: PropTypes.arrayOf(filterItemShape),
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

FilterBox.defaultProps = {
  items: [],
  style: {},
};

export { FilterBox };
