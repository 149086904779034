import { createEvaEntityService, createMelenaService } from '@globit/api-services';
import { config } from 'src/configs';


const createSessionCommentService = (proxy, scope) => {
  const sessionCommentService = createEvaEntityService(config.api.services.eva, scope, 'session-comment', proxy);
  const melenaService = createMelenaService(config.api.services.melena, proxy);

  return {
    ...sessionCommentService,
    search: params => melenaService.resolve({
      rel: 'request',
      method: 'POST',
      href: sessionCommentService.getSearchEndpoint(),
      data: params,
    }, 'resolve_path=*.*.owners.*&ignore_path=*.*.session'),
    create: data => sessionCommentService.create(data),
  };
};

export { createSessionCommentService };
