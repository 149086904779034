import { useSelector, useDispatch } from 'react-redux';
import { speakerItemActions } from 'src/store/speakers/item/actions';
import { selectSpeakerItem, selectSpeakerId, selectSpeakerItemLoading } from 'src/store/speakers/item/selectors';


const useSpeakerItemState = () => ({
  speakerId: useSelector(selectSpeakerId),
  speakerItem: useSelector(selectSpeakerItem),
  isSpeakerItemLoading: useSelector(selectSpeakerItemLoading),
});

const useSpeakerItemDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSpeakerItem: id => dispatch(speakerItemActions.fetch(id)),
  });
};

export { useSpeakerItemState, useSpeakerItemDispatch };
