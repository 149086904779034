import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterBox } from 'src/view/components/FilterBox';
import { filterShape } from 'src/view/propTypes/filter';
import { Spinner } from 'src/view/components/Spinner';


const Filters = ({ isLoading, activeFilters, getFilters, additionalFilters, setSearchParams }) => {
  useEffect(() => {
    getFilters();
  }, []);

  const handleChange = name => value => setSearchParams({ filters: { ...activeFilters, [name]: value }, offset: 0 });
  const styles = { gridTemplateColumns: additionalFilters.length <= 3
    ? additionalFilters.map(() => '1fr').join(' ') : '1fr 1fr 1fr' };

  return (
    <Spinner isLoading={isLoading}>
      <div className='op-filters-container' style={{ ...styles }}>
        {additionalFilters.map(({ name, ...filter }) =>
          <FilterBox
            {...filter}
            key={filter._id}
            activeFilter={activeFilters && activeFilters[name]}
            onChange={handleChange(name)}
          />,
        )}
      </div>
    </Spinner>
  );
};

Filters.propTypes = {
  activeFilters: PropTypes.object,
  getFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  additionalFilters: PropTypes.arrayOf(filterShape),
  setSearchParams: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  additionalFilters: [],
};

export { Filters };
