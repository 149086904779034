import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorState } from 'src/store/error/hooks';


const ErrorHandler = ({ children }) => {
  const { error } = useErrorState();
  const { t } = useTranslation();

  return error ? (
    <Result
      status={error.status}
      title={t(error.messageKey)}
    />
  ) : children;
};

export { ErrorHandler };
