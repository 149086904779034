import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { SPEAKER_LIST_LEFT, SPEAKER_LIST_RIGHT } from 'src/constants/advertisements';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { useAccessState } from 'src/store/access/hooks';
import { useSearchParamsState } from 'src/store/searchParams/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { SpeakerList } from 'src/view/pages/Speakers/List';
import { SpeakerFilters } from 'src/view/pages/Speakers/Filters';
import { SpeakerNavigation } from 'src/view/pages/Speakers/Navigation';
import { SearchBox } from 'src/view/components/SearchBox';
import { SpeakerAdvertisement } from 'src/view/pages/Speakers/Advertisement';
import { ClearFilters } from 'src/view/components/ClearFilters';


const Speakers = () => {
  const { search } = useSearchParamsState();
  const { accessStatus, accessRoles } = useAccessState();

  const [searchValue, setSearchValue] = useState(search);
  const [isShowFilters, setShowFilters] = useState(true);
  const windowSize = useWindowSize();

  const { getPerson } = usePersonDispatch();
  const { person } = usePersonState();

  useEffect(() => setShowFilters(windowSize.width < 850), [windowSize.width]);
  useEffect(() => {
    if (accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT)) {
      getPerson();
    }
  }, [accessStatus, accessRoles]);

  return (
    <div className="op-layout">
      <header>
        <SpeakerNavigation />
      </header>
      <div className="op-container">
        <div className="banner">
          <SpeakerAdvertisement side={SPEAKER_LIST_LEFT} />
        </div>
        <div className="op-content">
          <section>
            {windowSize.width < 850 && (
              <Button onClick={() => setShowFilters(!isShowFilters)} className='filter-button'>
                Filters
              </Button>)}
            <div className={`top-bar${isShowFilters ? '--hide' : ''}`}>
              <SearchBox
                favoriteEntities={person?.speakerFavorites}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <div className='speakers-filters'>
                <SpeakerFilters />
              </div>
              <ClearFilters searchValue={searchValue} setSearchValue={setSearchValue} />
            </div>
            <SpeakerList />
          </section>
        </div>
        <div className="banner">
          <SpeakerAdvertisement side={SPEAKER_LIST_RIGHT} />
        </div>
      </div>
    </div>
  );
};

export { Speakers };
