import { createSelector } from 'reselect';
import { NAVIGATION_FILTER_TYPE } from 'src/constants/filters';


const selectSessionFilters = state => state.session.filters.entries;
const selectSessionFiltersLoading = state => state.session.filters.loading > 0;

const selectSessionNavigationFilters = createSelector(
  state => selectSessionFilters(state),
  filters => (filters || []).filter(item => item.view === NAVIGATION_FILTER_TYPE),
);

const selectSessionAdditionalFilters = createSelector(
  state => selectSessionFilters(state),
  filters => (filters || []).filter(item => item.view !== NAVIGATION_FILTER_TYPE),
);

export {
  selectSessionFilters,
  selectSessionFiltersLoading,
  selectSessionNavigationFilters,
  selectSessionAdditionalFilters,
};
