import PropTypes from 'prop-types';
import {
  RADIO_FILTER_TYPE,
  CHECKBOX_FILTER_TYPE,
  SELECT_FILTER_TYPE,
  NAVIGATION_FILTER_TYPE,
  PREFIX_LIST_FILTER_TYPE,
} from 'src/constants/filters';


const filterItemShape = PropTypes.shape({
  label: PropTypes.string,
  filter: PropTypes.object,
});

const filterShape = PropTypes.shape({
  active: PropTypes.bool,
  title: PropTypes.string,
  scope: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.arrayOf(filterItemShape),
  operator: PropTypes.string,
  view: PropTypes.oneOf([
    RADIO_FILTER_TYPE,
    CHECKBOX_FILTER_TYPE,
    SELECT_FILTER_TYPE,
    NAVIGATION_FILTER_TYPE,
    PREFIX_LIST_FILTER_TYPE,
  ]),
});

export { filterItemShape, filterShape };
