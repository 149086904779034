import { createEvaEntityService } from '@globit/api-services';
import { config } from 'src/configs';


const createPersonService = (proxy, scope) => {
  const personService = createEvaEntityService(config.api.services.eva, scope, 'person', proxy);

  return {
    ...personService,
    findPersonByOwnerRef: ref => personService.search({
      query: {
        contains: { owners: [{ $ref: ref }] },
      },
    }),
  };
};

export { createPersonService };
