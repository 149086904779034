import { createCoauthClientService, createCoauthAccountService } from '@globit/api-services';
import { config } from 'src/configs';


const createAuthenticationService = proxy => {
  const coauthClientService = createCoauthClientService(config.api.services.coauth, proxy);
  const coauthAccountService = createCoauthAccountService(config.api.services.coauth, proxy);

  return {
    authenticateClient: () => coauthClientService.authenticate(),
    authenticateAccount: ({ username, password }) => coauthAccountService.authenticate(username, password),
    tokenCheck: token => coauthAccountService.tokenCheck(token),
  };
};

export { createAuthenticationService };
