import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { sessionListActions } from 'src/store/sessions/list/actions';


const sessionListReducer = combineReducers({
  entries: createReducer([], {
    [sessionListActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  totalCount: createReducer(null, {
    [sessionListActions.setTotalCount]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionListActions.fetch.type],
      stop: [sessionListActions.fetchSucceeded.type, sessionListActions.fetchFailed.type],
    },
  ]),
});

export { sessionListReducer };
