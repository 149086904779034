import React from 'react';
import PropTypes from 'prop-types';
import BEM from 'bem-class-names-builder';
import { NAVIGATION_MAIN, NAVIGATION_SECONDARY } from 'src/constants/navigation';
import { filterShape } from 'src/view/propTypes/filter';
import { isFilterActive } from 'src/utils/filter';
import './styles.less';


const bem = new BEM('navigation');

const TopFilter = ({ items, activeFilter, onChange, name, operator, mode, nested }) => {
  const selectedItems = activeFilter ? items.filter(item => isFilterActive(item, activeFilter.items)) : [];

  const handleClick = value => {
    const updatedItems = selectedItems.includes(value)
      ? selectedItems.filter(i => i !== value)
      : [...selectedItems, value];

    onChange({ operator, mode, nested, items: updatedItems });
  };

  return (
    <div className={bem.mods(name).toString()}>
      {items.map(item => (
        <div
          key={item.label}
          className={bem.elem('item').mix(selectedItems.includes(item) && 'active').toString()}
          onClick={() => handleClick(item)}
        >
          {item.label}
        </div>),
      )}
    </div>
  );
};

TopFilter.propTypes = {
  items: PropTypes.arrayOf(filterShape).isRequired,
  activeFilter: PropTypes.object,
  name: PropTypes.oneOf([NAVIGATION_MAIN, NAVIGATION_SECONDARY]).isRequired,
  mode: PropTypes.string.isRequired,
  nested: PropTypes.string,
  operator: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { TopFilter };
