import { createActions } from 'src/modules/createActions';


const sessionCommentsItemActions = createActions('session-comment-item', [
  'CREATE',
  'CREATE_SUCCEEDED',
  'CREATE_FAILED',
]);

export { sessionCommentsItemActions };
