import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ShareAltOutlined, StarOutlined, VideoCameraOutlined,
  DownOutlined, CaretRightOutlined,
  StarFilled, UserOutlined,
} from '@ant-design/icons';
import BEM from 'bem-class-names-builder';
import { Avatar } from 'antd';
import parse, { attributesToProps } from 'html-react-parser';
import { useInitState } from 'src/store/init/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { speakerShape } from 'src/view/propTypes/speaker';
import { SocialButtons } from 'src/view/pages/Sessions/Item/components/SocialButtons';
import { ShareModal } from 'src/view/pages/Sessions/Item/components/ShareModal';
import { Snippet } from 'src/view/components/Snippet';
import { Buttons } from 'src/view/components/Buttons';
import { getFormattedTimeRange } from 'src/utils/date';
import { useCountryListState } from 'src/store/countries/list/hooks';
import { getCountryName } from 'src/utils/country';
import './styles.less';


const bem = new BEM('op-speaker-item');

const SpeakersItem = ({ isOverview = false, ...speaker }) => {
  const { id, view, contributions = [], profileImage, parentSession, isFavorite, _self } = speaker;
  const { liveURL, ondemandURL } = parentSession || {};
  const { countryList } = useCountryListState();
  const { t } = useTranslation();

  const { person } = usePersonState();
  const { updatePerson } = usePersonDispatch();

  const { congressTimeFormat, congressDateFormat } = useInitState();

  const [isCollapsed, setCollapsed] = useState(!isOverview);
  const [isShareModal, setIsShareModal] = useState(false);

  const buttons = [
    {
      isHidden: !liveURL,
      color: 'red',
      label: t('liveNow'),
      icon: <CaretRightOutlined/>,
      type: 'primary',
      onClick: () => {
        window.location.href = 'test';
      },
    },
    {
      isHidden: !ondemandURL,
      color: 'blue',
      label: t('recording'),
      icon: <VideoCameraOutlined/>,
      type: 'primary',
      onClick: () => {
        window.location.href = 'test';
      },
    },
  ].filter(b => !b.isHidden);

  const socialButtons = [
    {
      title: t('favorite'),
      icon: StarOutlined,
      filledIcon: StarFilled,
      onClick: () => updatePerson({
        _id: person._id,
        speakerFavorites: isFavorite
          ? person.speakerFavorites.filter(({ $ref }) => $ref !== _self.$ref)
          : [...person.speakerFavorites, { $ref: _self.$ref }],
      }),
      isHidden: false,
      isActive: isFavorite,
    },
    {
      title: t('share'),
      icon: ShareAltOutlined,
      component: (
        <ShareModal
          title={t('shareTheEvent')}
          visible={isShareModal}
          handleClose={() => setIsShareModal(false)}
          value={''}
        />
      ),
      onClick: () => setIsShareModal(true),
      isHidden: false,
    },
  ];


  const getAdjustedContribution = (start_time, end_time, index) => {
    const time = getFormattedTimeRange(start_time, end_time, congressTimeFormat);
    const date = start_time.format(congressDateFormat?.short);

    const adjustedListItemView = view && parse(view, {
      replace: domNode => {
        const { attribs, name, children } = domNode || {};

        if (attribs?.['data-op-replacement']?.includes('op-speaker-time') || attribs?.class?.includes('replaceable-time')) {
          const attributes = attributesToProps(attribs);

          return React.createElement(name, attributes, time);
        }

        if (attribs?.['data-op-replacement']?.includes('op-speaker-date') || attribs?.class?.includes('replaceable-date')) {
          const attributes = attributesToProps(attribs);

          return React.createElement(name, attributes, date);
        }

        if (attribs?.['data-op-replacement']?.includes('op-country-name') || attribs?.class?.includes('op-country-code')) {
          const attributes = attributesToProps(attribs);
          const countryName = getCountryName(countryList, children);

          return React.createElement(name, attributes, countryName);
        }

        return domNode;
      },
    });

    const speakerContributionsView = adjustedListItemView?.find(elem => elem.props?.className?.includes('speaker-contributions'));
    const speakerContributions = speakerContributionsView?.props?.children?.filter(el => React.isValidElement(el));

    return speakerContributions[index];
  };

  return (
    <div className={bem} key={id}>
      <div className={bem.elem('header')}>
        {
          !!contributions.length
          && <DownOutlined
            className={bem.elem('caret').toString()}
            style={{ fontSize: '16px' }}
            rotate={isCollapsed ? 0 : 180}
            onClick={() => setCollapsed(!isCollapsed)}
          />
        }
        <SocialButtons buttons={socialButtons}/>
        <div className={bem.elem('image')}>
          <Avatar src={profileImage} shape="square" size={64} icon={<UserOutlined />} />
        </div>
        <div className={bem.elem('body')}>
          <Snippet selector=".speaker-body" view={view} />
        </div>
      </div>
      <div className={bem.elem('contributions').mods({ hidden: isCollapsed })}>
        {contributions.map((contribution, index) => (
          <div className={bem.elem('contributions-item')}>
            <div style={{ flex: 3 }}>
              {getAdjustedContribution(contribution.start_time, contribution.end_time, index)}
            </div>
            <div style={{ flex: 1 }}>
              <Buttons direction={'column'} buttons={buttons} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SpeakersItem.propTypes = {
  speaker: speakerShape,
};

SpeakersItem.defaultProps = {
  speaker: {},
};

export { SpeakersItem };
