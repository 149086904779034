import { combineReducers } from 'redux';
import { speakerListReducer } from 'src/store/speakers/list/reducer';
import { speakerFiltersReducer } from 'src/store/speakers/filters/reducer';
import { speakerAdvertisementsReducer } from 'src/store/speakers/advertisements/reducer';
import { speakerItemReducer } from 'src/store/speakers/item/reducer';
import { speakerFavoritesReducer } from 'src/store/speakers/favorites/reducer';


const speakerReducer = combineReducers({
  list: speakerListReducer,
  item: speakerItemReducer,
  filters: speakerFiltersReducer,
  advertisements: speakerAdvertisementsReducer,
  favorites: speakerFavoritesReducer,
});

export { speakerReducer };
