import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { LOCAL_TIMEZONE, VENUE_TIMEZONE } from 'src/constants/timezones';
import { SESSION_TYPE_SESSION } from 'src/constants/session';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'src/constants/calendar';


const moment = extendMoment(Moment);

const getFormattedDate = (date, format = 'DD MMMM YYYY') => moment(date).utc().format(format).replace(/\//g, '.');

const getFormattedDay = date => moment(date).utc().format('dddd');

const getDaysRange = (startDay, endDay) => {
  const range = moment.range(new Date(startDay), new Date(endDay));

  return Array.from(range.by('day')).map(date => moment(date).toISOString());
};

const getActiveDay = (startDay, endDay, currentDay) => {
  const daysRange = getDaysRange(startDay, endDay);
  const index = daysRange.findIndex(date => getFormattedDate(date) === getFormattedDate(currentDay));

  if (index === -1) {
    return moment().isBefore(startDay) ? 0 : daysRange.length - 1;
  }
  return index;
};

const getTimePeriod = (defaultTimeStart, defaultTimeEnd, activeDay) => {
  let agendaDateStart = moment().startOf('day');

  let agendaDateEnd = moment().endOf('day');

  if (defaultTimeStart) {
    const minutesToAlignRange = defaultTimeStart.minutes() % 30;

    agendaDateStart = defaultTimeStart.subtract(minutesToAlignRange, 'minutes');
  }
  if (defaultTimeEnd) {
    agendaDateEnd = defaultTimeEnd.isSame(activeDay, 'day') ? defaultTimeEnd.add(1, 'days').startOf('day') : defaultTimeEnd;
  }
  const range = moment.range(agendaDateStart, agendaDateEnd);

  return Array.from(range.by('minutes', { step: 30 }));
};

const getTimezoneOffset = timezone => timezone && timezone.replace('UTC', '');

const calculateDateTime = (venueOffset, timezone) => date => {
  if (timezone === LOCAL_TIMEZONE) {
    const localTimezoneOffset = new Date().getTimezoneOffset();

    return moment(date).subtract(localTimezoneOffset, 'minutes').utc();
  }
  if (timezone === VENUE_TIMEZONE) {
    return moment(date).add(getTimezoneOffset(venueOffset), 'hours').utc();
  }
  return moment(date).add(getTimezoneOffset(timezone), 'hours').utc();
};

const getFormattedTimeRange = (start, end, format) =>
  `${moment(start).format(format)}-${moment(end).format(format)}`;

const calculateItemTime = (venueOffset, timezone) => {
  const adjustTime = calculateDateTime(venueOffset, timezone);

  return item => (item.type === SESSION_TYPE_SESSION ? ({
    ...item,
    start_date_time: adjustTime(item.start_date_time),
    end_date_time: adjustTime(item.end_date_time),
    utc_start_time: item.start_date_time,
    utc_end_time: item.end_date_time,
  }) : ({
    ...item,
    contributions: item.contributions && item.contributions.map(contribution => ({
      ...contribution,
      start_date_time: adjustTime(
        contribution.start_date_time ? contribution.start_date_time : contribution.parentSession.start_date_time),
      end_date_time: adjustTime(
        contribution.end_date_time ? contribution.end_date_time : contribution.parentSession.end_date_time),
    })),
  }));
};

const toDateFormat = date => moment(date).format(DATE_FORMAT);
const getUtcDate = (date, time) => moment(`${toDateFormat(date)} ${time}:00`).utc();
const getFullDate = time => moment(moment(time).format(FULL_DATE_FORMAT));
const dateNow = moment(moment.now()).utc().format();

export {
  moment,
  getFormattedDate,
  getFormattedDay,
  getDaysRange,
  getActiveDay,
  getTimePeriod,
  calculateDateTime,
  getFormattedTimeRange,
  calculateItemTime,
  getUtcDate,
  getFullDate,
  dateNow,
};
