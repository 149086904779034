import React, { useEffect, useRef } from 'react';


const BasePlayer = ({ initializePlayer, poster }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://player.live-video.net/1.31.0/amazon-ivs-player.min.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      initializePlayer(videoRef.current);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <video ref={videoRef} playsinline controls poster={poster} width="100%" />;
};

export { BasePlayer };
