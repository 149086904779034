import { createActions } from 'src/modules/createActions';


const speakerAdvertisementsActions = createActions('speaker-advertisements', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);

export { speakerAdvertisementsActions };
