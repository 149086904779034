import React from 'react';
import { useSelector } from 'react-redux';
import { useConfigs } from 'src/hooks/useConfigs';
import { useSpeakerFiltersState, useSpeakerFiltersDispatch } from 'src/store/speakers/filters/hooks';
import { useSearchParamsDispatch, useSearchParamsState } from 'src/store/searchParams/hooks';
import { Filters } from 'src/view/components/Filters';
import { selectSpeakerAdditionalFilters } from 'src/store/speakers/filters/selectors';


const SpeakerFilters = () => {
  const { language } = useConfigs();
  const { filters } = useSearchParamsState();
  const { isSpeakerFiltersLoading } = useSpeakerFiltersState();
  const { getSpeakerFilters } = useSpeakerFiltersDispatch(language);
  const { setSearchParams } = useSearchParamsDispatch();
  const speakerAdditionalFilters = useSelector(selectSpeakerAdditionalFilters);

  return (
    <Filters
      isLoading={isSpeakerFiltersLoading}
      activeFilters={filters}
      getFilters={getSpeakerFilters}
      additionalFilters={speakerAdditionalFilters}
      setSearchParams={setSearchParams}
    />
  );
};

export { SpeakerFilters };
