const BOOKMARK = 'tpl-op-session-item-social-buttons__button-bookmark';
const BOOKMARK_ACTIVE = 'tpl-op-session-item-social-buttons__button-bookmark--bookmarked';
const SPEAKER_BOOKMARK = 'tpl-op-speaker-item-social-buttons__button-bookmark';
const SPEAKER_BOOKMARK_ACTIVE = 'tpl-op-speaker-item-social-buttons__button-bookmark--bookmarked';
const CARET = 'tpl-op-session-item__caret';
const CARET_OPEN = 'tpl-op-session-item__caret-open';
const SPEAKER_CARET = 'tpl-op-speaker-item__caret';
const SPEAKER_CARET_OPEN = 'tpl-op-speaker-item__caret-open';
const ABSTRACT = 'tpl-op-button--abstract-main';
const ABSTRACT_CONTRIBUTION = 'tpl-op-button--abstract';
const SESSION_OPEN = 'tpl-op-session-item--open';
const SPEAKER_OPEN = 'tpl-op-speaker-item--open';
const SHARE = 'tpl-op-session-item-social-buttons__button-share';
const CALENDAR = 'tpl-op-session-item-social-buttons__button-calendar';
const MORE_DETAIL_BUTTON = 'tpl-op-session__button-more-detail';

export { BOOKMARK, BOOKMARK_ACTIVE, CARET, CARET_OPEN, ABSTRACT, ABSTRACT_CONTRIBUTION, SESSION_OPEN, SHARE, CALENDAR,
  SPEAKER_OPEN, SPEAKER_CARET, SPEAKER_CARET_OPEN, SPEAKER_BOOKMARK, SPEAKER_BOOKMARK_ACTIVE, MORE_DETAIL_BUTTON };
