import { createActions } from 'src/modules/createActions';


const sessionFavoritesActions = createActions('session-favorites', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
  'ADD',
  'ADD_SUCCEEDED',
  'ADD_FAILED',
  'REMOVE',
  'REMOVE_SUCCEEDED',
  'REMOVE_FAILED',
]);

export { sessionFavoritesActions };
