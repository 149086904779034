import React, { useEffect } from 'react';
import { useSessionListState, useSessionListDispatch } from 'src/store/sessions/list/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { useSessionFiltersState } from 'src/store/sessions/filters/hooks';
import { useSessionFavoritesState, useSessionFavoritesDispatch } from 'src/store/sessions/favorites/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { useFilesState } from 'src/store/files/hooks';
import { NewSessionItem } from 'src/view/pages/Sessions/NewItem';
import { List } from 'src/view/components/List';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { SESSIONS } from 'src/constants/list';


const SessionList = () => {
  const { sessionList, isSessionListLoading, sessionTotalCount } = useSessionListState();
  const { getSessionsList } = useSessionListDispatch();
  const { fetchFavorites, addSessionToFavorites, removeSessionFromFavorites } = useSessionFavoritesDispatch();
  const { setSearchParams } = useSearchParamsDispatch();
  const { sessionFavorites, isSessionFavoritesLoading } = useSessionFavoritesState();
  const { sessionFilters } = useSessionFiltersState();
  const { accessStatus, accessRoles } = useAccessState();
  const { isFileLoading } = useFilesState();
  const searchParams = useSearchParamsState();
  const searchParamsList = { ...searchParams, viewMode: 'list' };

  useEffect(() => {
    if (accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT)) {
      fetchFavorites();
    }
  }, []);

  return (
    <List
      listType={SESSIONS}
      isLoading={isSessionListLoading || isSessionFavoritesLoading || isFileLoading}
      list={sessionList}
      allFilters={sessionFilters}
      getList={() => getSessionsList(searchParamsList)}
      favoriteEntities={sessionFavorites}
      addToFavorites={addSessionToFavorites}
      removeFromFavorites={removeSessionFromFavorites}
      totalCount={sessionTotalCount}
      setSearchParams={setSearchParams}
      item={NewSessionItem}
      {...searchParamsList}
    />
  );
};

export { SessionList };
