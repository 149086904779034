import { call, put, select } from 'redux-saga/effects';
import { createPersonService } from 'src/api/personService';
import { selectScope } from 'src/store/scope/selectors';
import { personItemActions } from 'src/store/persons/item/actions';
import { createAuthProxy } from 'src/store/access/utils';
import { store } from 'src/store/store';
import { selectAccessRef } from 'src/store/access/selectors';


function* getPerson() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const accountRef = yield select(selectAccessRef);
  const personService = createPersonService(authProxy, scope);

  try {
    const person = yield call(personService.findPersonByOwnerRef, accountRef);

    yield put(personItemActions.fetchSucceeded(person.items[0]));
  } catch (e) {
    // TODO: add notification for error
    yield put(personItemActions.fetchFailed(e.message));
  }
}

export { getPerson };
