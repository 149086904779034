import PropTypes from 'prop-types';


const sessionShape = PropTypes.shape({
  legacyId: PropTypes.string,
  view: PropTypes.string,
  contributions: PropTypes.array,
  premium: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    null,
  ]),
  additionalDocuments: PropTypes.array,
  playerUrl: PropTypes.string,
});

export { sessionShape };
