import { useSelector } from 'react-redux';
import { selectScope } from 'src/store/scope/selectors';


const useScopeState = () => ({
  scope: useSelector(selectScope),
});


export { useScopeState };
