import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { createAuthProxy } from 'src/store/access/utils';
import { speakerItemActions } from 'src/store/speakers/item/actions';
import { store } from 'src/store/store';
import { selectScope } from 'src/store/scope/selectors';


function* fetchSpeakerItem({ payload: id }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const speaker = yield call(selmaService.getSpeakerItem, id);

    yield put(speakerItemActions.fetchSucceeded(speaker));
  } catch (e) {
    yield put(speakerItemActions.fetchFailed(e.message));
  }
}

export { fetchSpeakerItem };
