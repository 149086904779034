import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { speakerListActions } from 'src/store/speakers/list/actions';


const speakerListReducer = combineReducers({
  entries: createReducer([], {
    [speakerListActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  totalCount: createReducer(null, {
    [speakerListActions.setTotalCount]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [speakerListActions.fetch.type],
      stop: [speakerListActions.fetchSucceeded.type, speakerListActions.fetchFailed.type],
    },
  ]),
});

export { speakerListReducer };
