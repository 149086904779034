import { createActions } from 'src/modules/createActions';


const sessionWatchTimeActions = createActions('session-watch-time', [
  'CREATE',
  'CREATE_SUCCEEDED',
  'CREATE_FAILED',
]);

export { sessionWatchTimeActions };
