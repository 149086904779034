import React from 'react';
import BEM from 'bem-class-names-builder';


const bem = new BEM('op-layout');

const Layout = ({ children }) => (
  <div className={bem}>{children}</div>
);

export { Layout };
