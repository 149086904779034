import { useSelector, useDispatch } from 'react-redux';
import { speakerAdvertisementsActions } from 'src/store/speakers/advertisements/actions';
import {
  selectSpeakerAdvertisements,
  selectSpeakerAdvertisementsLoading,
} from 'src/store/speakers/advertisements/selectors';


const useSpeakerAdvertisementsState = () => ({
  speakerAdvertisements: useSelector(selectSpeakerAdvertisements),
  isSpeakerAdvertisementsLoading: useSelector(selectSpeakerAdvertisementsLoading),
});

const useSpeakerAdvertisementsDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSpeakerAdvertisements: () => dispatch(speakerAdvertisementsActions.fetch()),
  });
};

export { useSpeakerAdvertisementsState, useSpeakerAdvertisementsDispatch };
