import { useLocation } from 'react-router';
import { parseUrlSearchQuery } from 'src/utils/path';


const useConfigs = () => {
  const location = useLocation();
  const query = parseUrlSearchQuery(window.location.search);
  const { dataset } = document.getElementById('online-program');

  const isNEOSPlugin = Object.prototype.hasOwnProperty.call(dataset, 'neos');
  const route = dataset.route || query.route;
  const sessionId = dataset.session || dataset.sessionId || query.sessionId || query.sessionid;
  const pathname = route ? `/${route}` : location.pathname;
  const language = dataset.language || query.language || 'en-US';

  return ({
    isNEOSPlugin,
    sessionId,
    pathname,
    language,
  });
};

export { useConfigs };
