import { call, put, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { createFileService } from 'src/api/fileService';
import { fileActions } from 'src/store/files/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';


function* downloadFile({ payload: { _file, filename } }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const fileService = createFileService(authProxy, scope);

  try {
    const fileBlob = yield call(fileService.downloadFile, _file);

    saveAs(fileBlob, filename);
    yield put(fileActions.downloadSucceeded());
  } catch (e) {
    yield put(fileActions.downloadFailed(e.message));
  }
}

export { downloadFile };
