import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { sessionCommentsListActions } from 'src/store/sessionComments/list/actions';
import { createLoadingReducer } from 'src/modules/loadingReducer';


const sessionCommentsListReducer = combineReducers({
  entries: createReducer([], {
    [sessionCommentsListActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  totalCount: createReducer(null, {
    [sessionCommentsListActions.setTotalCount]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionCommentsListActions.fetch.type],
      stop: [sessionCommentsListActions.fetchSucceeded.type, sessionCommentsListActions.fetchFailed.type],
    },
  ]),
});

export { sessionCommentsListReducer };
