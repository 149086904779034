import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { initReducer } from 'src/store/init/reducer';
import { accessRouter } from 'src/store/access/reducer';
import { sessionReducer } from 'src/store/sessions/reducer';
import { speakerReducer } from 'src/store/speakers/reducer';
import { filesReducer } from 'src/store/files/reducer';
import { calendarReducer } from 'src/store/calendar/reducer';
import { scopeReducer } from 'src/store/scope/reducer';
import { errorReducer } from 'src/store/error/reducer';
import { sessionCommentsReducer } from 'src/store/sessionComments/reducer';
import { personReducer } from 'src/store/persons/reducer';
import { countryReducer } from './countries/reducer';


const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  scope: scopeReducer,
  error: errorReducer,
  access: accessRouter,
  init: initReducer,
  session: sessionReducer,
  speaker: speakerReducer,
  calendar: calendarReducer,
  files: filesReducer,
  sessionComments: sessionCommentsReducer,
  person: personReducer,
  country: countryReducer,
});

export { createRootReducer };
