import React from 'react';
import { Modal as AntdModal } from 'antd';


const Modal = ({ width, closable, title, visible, handleClose, children, scrollable = false, footer = null }) => (
  <AntdModal
    closable={closable}
    title={title}
    bodyStyle={{ maxHeight: 800, overflowY: scrollable && 'scroll' }}
    width={width || 800}
    visible={visible}
    onOk={handleClose}
    onCancel={handleClose}
    footer={footer}
  >
    {children}
  </AntdModal>
);

export { Modal };
