import React, { useState, useEffect } from 'react';
import BEM from 'bem-class-names-builder';
import PropTypes from 'prop-types';
import {
  SESSION_LIST_LEFT,
  SESSION_LIST_RIGHT,
  SPEAKER_LIST_LEFT,
  SPEAKER_LIST_RIGHT,
} from 'src/constants/advertisements';
import { Spinner } from 'src/view/components/Spinner';
import { advertisementShape } from 'src/view/propTypes/advertisement';
import './styles.less';


const bem = new BEM('op-advertisement');

const Advertisement = ({ isLoading, side, advertisements, rotationTime }) => {
  const adsToDisplay = advertisements.filter(({ pageArea }) => pageArea === side);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);

    const interval = setInterval(() => {
      const updateIndex = prevIndex =>
        (prevIndex === adsToDisplay.length - 1 ? 0 : prevIndex + 1);

      setIndex(prevIndex => updateIndex(prevIndex));
    }, rotationTime);

    return () => clearInterval(interval);
  }, [adsToDisplay]);

  const { _id, view } = adsToDisplay[index] || {};

  return (
    <Spinner isLoading={isLoading}>
      <div key={_id} className={bem} dangerouslySetInnerHTML={{ __html: view }} />
    </Spinner>
  );
};

Advertisement.propTypes = {
  side: PropTypes.oneOf([
    SESSION_LIST_LEFT,
    SESSION_LIST_RIGHT,
    SPEAKER_LIST_LEFT,
    SPEAKER_LIST_RIGHT,
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  advertisements: PropTypes.arrayOf(advertisementShape),
  rotationTime: PropTypes.number,
};

Advertisement.defaultProps = {
  advertisements: [],
};

export { Advertisement };

