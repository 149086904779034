import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import BEM from 'bem-class-names-builder';
import { Button } from 'antd';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { useInitState } from 'src/store/init/hooks';
import { speakerShape } from 'src/view/propTypes/speaker';
import { Snippet } from 'src/view/components/Snippet';
import { getFormattedTimeRange } from 'src/utils/date';
import { ABSTRACT_CONTRIBUTION, SPEAKER_CARET, SPEAKER_CARET_OPEN, SPEAKER_OPEN, SPEAKER_BOOKMARK,
  SPEAKER_BOOKMARK_ACTIVE } from 'src/constants/template';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { useAccessState } from 'src/store/access/hooks';
import { usePersonState } from 'src/store/persons/item/hooks';
import { Modal } from 'src/view/components/Modal';
import { getContributionAbstractContentUrl } from 'src/utils/session';
import { useCountryListState } from 'src/store/countries/list/hooks';
import { getCountryName } from 'src/utils/country';
import { getSpeakerRefById } from 'src/utils/speaker';


const bem = new BEM('tlp-op-speaker-item');

const NewSpeakersItem = speaker => {
  const { id, view, contributions = [], isFavorite, addToFavorites, removeFromFavorites } = speaker;
  const { t } = useTranslation();
  const { congressTimeFormat, congressDateFormat } = useInitState();
  const { accessStatus, accessRoles } = useAccessState();
  const { countryList } = useCountryListState();
  const { person } = usePersonState();
  const [contributionAbstractId, setContributionAbstractId] = useState(null);

  const getAdjustedSpeaker = view && parse(view, {
    replace: ({ attribs, name, children }) => {
      if (attribs?.['data-op-replacement']?.includes('op-speaker-time') || attribs?.class?.includes('replaceable-time')) {
        const attributes = attributesToProps(attribs);
        const contribution = contributions[attributes.index - 1];

        const time = getFormattedTimeRange(
          contribution?.start_date_time, contribution?.end_date_time, congressTimeFormat);

        return React.createElement(name, attributes, time);
      }

      if (attribs?.['data-op-replacement']?.includes('op-speaker-date') || attribs?.class?.includes('replaceable-date')) {
        const attributes = attributesToProps(attribs);
        const contribution = contributions[attributes.index - 1];

        const date = contribution?.start_date_time.format(congressDateFormat?.short);

        return React.createElement(name, attributes, date);
      }

      if (
        attribs?.['data-op-replacement']?.includes('op-speaker-change-bookmark')
        || attribs?.class?.includes(SPEAKER_BOOKMARK)
      ) {
        const attributes = attributesToProps(attribs);

        const classNames = isFavorite
          ? `${attribs.class} ${SPEAKER_BOOKMARK_ACTIVE}`
          : attribs.class;

        return React.createElement(
          name,
          { ...attributes,
            title: t(attributes.title),
            class: classNames,
            onClick: () => (isFavorite
              ? removeFromFavorites({
                _id: person._id,
                speakerFavorites: (person.speakerFavorites || []).filter(({ $ref }) => $ref !== getSpeakerRefById(id)),
              })
              : addToFavorites({
                _id: person._id,
                speakerFavorites: [...(person.speakerFavorites || []), { $ref: getSpeakerRefById(id) }],
              })
            ) },
          accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT) ? domToReact(children) : '',
        );
      }

      if (attribs?.['data-op-replacement']?.includes('op-country-name') || attribs?.class?.includes('op-country-code')) {
        const attributes = attributesToProps(attribs);
        const countryName = getCountryName(countryList, children);

        return React.createElement(name, attributes, countryName);
      }

      return null;
    },
  });

  const contributionAbstractContentUrl = getContributionAbstractContentUrl(contributions, contributionAbstractId);

  return (
    <div className={bem} key={id} onClick={event => {
      const targetClassList = event.target.classList;
      const currentTargetClassList = event.currentTarget.classList;

      const bookmark = targetClassList.contains(SPEAKER_BOOKMARK);
      const bookmarkActive = targetClassList.contains(SPEAKER_BOOKMARK_ACTIVE);
      const abstractContribution = targetClassList.contains(ABSTRACT_CONTRIBUTION);
      const caret = targetClassList.contains(SPEAKER_CARET);
      const caretOpen = targetClassList.contains(SPEAKER_CARET_OPEN);

      if (bookmark && !bookmarkActive) {
        targetClassList.add(SPEAKER_BOOKMARK_ACTIVE);
      } else if (bookmark && bookmarkActive) {
        targetClassList.remove(SPEAKER_BOOKMARK_ACTIVE);
      }

      if (abstractContribution) {
        setContributionAbstractId(event.target.getAttribute('data-abstract-id'));
      }

      if (caret && !caretOpen) {
        targetClassList.add(SPEAKER_CARET_OPEN);
        currentTargetClassList.add(SPEAKER_OPEN);
      } else if (caret && caretOpen) {
        targetClassList.remove(SPEAKER_CARET_OPEN);
        currentTargetClassList.remove(SPEAKER_OPEN);
      }
    }}>
      {getAdjustedSpeaker}
      <Modal
        scrollable
        visible={contributionAbstractId}
        handleClose={() => setContributionAbstractId(null)}
        footer={[
          (contributionAbstractContentUrl
            && <Button
              className={bem.elem('download-abstract').toString()}
              key="download"
              type="primary"
              onClick={() => saveAs(contributionAbstractContentUrl)}
            >
              <DownloadOutlined />
              {t('downloadAbstract')}
            </Button>
          ),
          <Button key="submit" type="primary" onClick={() => setContributionAbstractId(null)}>
            {t('ok')}
          </Button>,
        ]}>
        <Snippet
          view={view}
          selector={`.tpl-op-speaker-contribution-abstract__${contributionAbstractId}`}/>
      </Modal>
    </div>
  );
};

NewSpeakersItem.propTypes = {
  speaker: speakerShape,
};

NewSpeakersItem.defaultProps = {
  speaker: {},
};

export { NewSpeakersItem };
