import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import BEM from 'bem-class-names-builder';
import { DownCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Event } from 'src/view/components/DaySchedule/components/Event';
import { DATE_FORMAT } from 'src/constants/calendar';
import { dateNow, getFullDate, getUtcDate } from 'src/utils/date';
import '../../styles.less';


const bem = new BEM('op-day-schedule');


const createDate = (startDateTime, time) => moment(`${moment(startDateTime).format(DATE_FORMAT)}T${time}Z`);


const TimeBlock = ({ timelineItemSize, availableRooms, sessions, hoursInBlock, expandTimeBlockForActiveSession,
  start, end, title, timeFormat, scrollOffsetX, selectedDate, sidewayScrolling }) => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const fullDate = sessions && sessions[0] && sessions[0].start_date_time;

  const getRoomSessions = room => (sessions || [])
    .filter(({ room: sessionRoom, start_date_time: startDate }) => (sessionRoom?.name === room
      && (getFullDate(startDate).isSameOrAfter(getUtcDate(fullDate, start)))
      && (getFullDate(startDate).isSameOrBefore(getUtcDate(fullDate, end)))));

  const isActiveSession = sessions.find(({ start_date_time: startDate, end_date_time: endDate }) =>
    moment(dateNow).isSameOrAfter(startDate) && moment(dateNow).isSameOrBefore(endDate));

  useEffect(() => {
    if (sessions && expandTimeBlockForActiveSession && isActiveSession) {
      setIsShown(true);
    }
  }, [sessions]);

  return (
    <div
      style={{
        height: isShown ? `${timelineItemSize * hoursInBlock.length + (sidewayScrolling ? 140 : 40)}px` : '40px',
        width: '100%',
        overflowY: 'hidden',
      }}
      className={bem.elem('op-events-time-block')}
    >
      <div className={bem.elem('op-events-block-title')} onClick={() => setIsShown(!isShown)}>
        {`${title} (${start}-${end})`}
        <DownCircleFilled className={bem.elem('op-collapse-button').mods(isShown ? 'down' : 'top').toString()} />
      </div>
      <div
        className={bem.elem('op-events-time-block-content')}
        style={{
          width: '100%',
          overflowX: sidewayScrolling === false ? 'hidden' : 'scroll',
          transform: sidewayScrolling && 'rotateX(180deg)',
        }}
      >
        <div style={{ transform: sidewayScrolling && 'rotateX(180deg)' }}>
        {sidewayScrolling && (<div className={bem.elem('op-events-rooms')}>
          {!!availableRooms.length && (
            <Fragment>
              <div className={bem.elem('op-header-rooms')} style={{ paddingLeft: sidewayScrolling
                  && '147px',
              transform: `translate3d(-${scrollOffsetX}%, 0, 0)` }}>
                {
                  availableRooms.map((room, i) => (
                    <div className={bem.elem('op-header-room')} key={i}>
                      { room }
                    </div>
                  ))
                }
              </div>
            </Fragment>
          )}
          {!availableRooms.length && (
            <div className={bem.elem('op-no-sessions')}>
              <span>{t('noSessionToday')}</span>
            </div>
          )}
        </div>)}
          <div style={{ display: 'flex' }}>
            <div
              className={bem.elem('op-timeline')}
              style={{ zIndex: 10, display: isShown ? 'block' : 'none', position: sidewayScrolling && 'sticky', left: 0 }}
            >
              {hoursInBlock.map(time => (
                <div
                  key={time}
                  className={bem.elem('op-time-slot')}
                  style={{ height: `${timelineItemSize}px` }}
                >
                  {time}
                </div>
              ))}
            </div>
            <div style={{
              transform: `translate3d(-${scrollOffsetX}%, 0, 0)`,
              display: isShown ? 'flex' : 'none',
              width: 'calc(100% - 138px)',
            }}>
              {availableRooms.map(room => (
                <div key={room} className={bem.elem('op-event-item')}>
                  {hoursInBlock.map(hour => (
                    <div
                      className={bem.elem('op-time-item')}
                      key={hour}
                      style={{ height: `${timelineItemSize}px` }}
                    />
                  ))}
                  {getRoomSessions(room).map(session => (
                    <Event
                      selectedDate={selectedDate}
                      hoursRangeLength={hoursInBlock.length + 1}
                      timelineItemSize={timelineItemSize}
                      timelineStart={createDate(session.start_date_time, start)}
                      timelineEnd={createDate(session.start_date_time, end)}
                      timeFormat={timeFormat}
                      key={session.public_number}
                      {...session}
                    />
                  ))}
                </div>))
              }
            </div>
          </div>
        </div>
      </div>
    </div>);
};

export { TimeBlock };
