import React, { createContext, useState, useEffect } from 'react';
import { useInitState } from 'src/store/init/hooks';
import { LOCAL_TIMEZONE } from 'src/constants/timezones';


const TimezoneContext = createContext({});

const TimezoneProvider = ({ children }) => {
  const { venueTimezoneOffset, timezones } = useInitState();

  const [defaultTimeZone = LOCAL_TIMEZONE, ...optionalTimeZones] = timezones || [];
  const [timezone, setTimezone] = useState(defaultTimeZone);

  useEffect(() => {
    const [firstTimeZone] = timezones || [];

    setTimezone(firstTimeZone);
  }, [Array.isArray(timezones), (timezones || []).length > 0]);

  return (
    <TimezoneContext.Provider value={{
      venueTimezoneOffset,
      timezones,
      timezone,
      setTimezone,
      optionalTimeZones,
    }}>
      {children}
    </TimezoneContext.Provider>);
};

export { TimezoneContext, TimezoneProvider };
