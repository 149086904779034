import { useSelector, useDispatch } from 'react-redux';
import { initActions } from 'src/store/init/actions';
import {
  selectInit,
  selectIsInitLoading,
  selectCongress,
  selectOnlineProgram,
  selectCongressRooms,
  selectTimezones,
  selectVenueTimezoneOffset,
  selectCongressStartDay,
  selectCongressEndDay,
  selectCongressAppearance,
  selectCongressTimeFormat,
  selectCongressDateFormat,
  selectDelayedAdditionalDocsSessionIds,
  selectAdvertisementRotationTime,
  selectCalendarTimeSlotHeight,
} from './selectors';


const useInitState = () => ({
  init: useSelector(selectInit),
  isInitLoading: useSelector(selectIsInitLoading),
  congress: useSelector(selectCongress),
  onlineprogram: useSelector(selectOnlineProgram),
  congressRooms: useSelector(selectCongressRooms),
  timezones: useSelector(selectTimezones),
  venueTimezoneOffset: useSelector(selectVenueTimezoneOffset),
  advertisementRotationTime: useSelector(selectAdvertisementRotationTime),
  congressStartDay: useSelector(selectCongressStartDay),
  congressEndDay: useSelector(selectCongressEndDay),
  congressAppearance: useSelector(selectCongressAppearance),
  congressTimeFormat: useSelector(selectCongressTimeFormat),
  congressDateFormat: useSelector(selectCongressDateFormat),
  delayedAdditionalDocsSessionIds: useSelector(selectDelayedAdditionalDocsSessionIds),
  calendarTimeSlotHeight: useSelector(selectCalendarTimeSlotHeight),
});

const useInitDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getInit: () => dispatch(initActions.fetch()),
  });
};

export { useInitState, useInitDispatch };
