import { useDispatch, useSelector } from 'react-redux';
import { personItemActions } from 'src/store/persons/item/actions';
import { selectPerson, selectPersonLoading } from 'src/store/persons/item/selectors';


const usePersonState = () => ({
  person: useSelector(selectPerson),
  isPersonLoading: useSelector(selectPersonLoading),
});

const usePersonDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getPerson: () => dispatch(personItemActions.fetch()),
    updatePerson: person => dispatch(personItemActions.updatePerson(person)),
  });
};

export { usePersonState, usePersonDispatch };
