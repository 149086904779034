import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { TimezoneSwitcher } from 'src/view/components/TimezoneSwitcher';
import { useConfigs } from 'src/hooks/useConfigs';
import { TimezoneContext } from 'src/contexts/TimezoneContext';


const SessionTimezone = () => {
  const { isNEOSPlugin } = useConfigs();
  const { optionalTimeZones } = useContext(TimezoneContext);
  const domElements = document.querySelectorAll('.op-session-time-toggle');

  const component = !!optionalTimeZones.length && <TimezoneSwitcher />;

  return isNEOSPlugin
    ? !!domElements.length && [...domElements].map(element => ReactDOM.createPortal(component, element))
    : component;
};

export { SessionTimezone };
