import { createReducer } from '@reduxjs/toolkit';
import { accessStatusActions } from 'src/store/access/status/actions';
import { ACCESS_UNKNOWN } from 'src/constants/access';


const accessStatusReducer = createReducer(ACCESS_UNKNOWN, {
  [accessStatusActions.set]: (state, action) => action.payload,
  [accessStatusActions.clean]: () => '',
});

export { accessStatusReducer };
