import { createActions } from 'src/modules/createActions';


const sessionCommentsListActions = createActions('session-comment-list', [
  'SET_TOTAL_COUNT',
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);

export { sessionCommentsListActions };
