import { createEvaEntityService } from '@globit/api-services';
import { config } from 'src/configs';


const createSessionService = (proxy, scope) => {
  const sessionService = createEvaEntityService(config.api.services.eva, scope, 'session', proxy);

  return {
    ...sessionService,
  };
};

export { createSessionService };
