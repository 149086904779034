import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { countryListActions } from 'src/store/countries/list/actions';


const countryListReducer = combineReducers({
  entries: createReducer([], {
    [countryListActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [countryListActions.fetch.type],
      stop: [countryListActions.fetchSucceeded.type, countryListActions.fetchFailed.type],
    },
  ]),
});

export { countryListReducer };
