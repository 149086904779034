import { useSelector, useDispatch } from 'react-redux';
import { searchParamsActions } from 'src/store/searchParams/actions';
import { selectSearchQuery } from 'src/store/searchParams/selectors';
import { parseUrlSearchQuery } from 'src/utils/path';


const useSearchParamsState = () => {
  const searchParams = parseUrlSearchQuery(useSelector(selectSearchQuery)).query || {};

  return ({
    limit: searchParams.limit,
    offset: searchParams.offset,
    search: searchParams.search,
    isFilterDisabled: searchParams.isFilterDisabled,
    favorites: searchParams.favorites,
    filters: searchParams.filters,
    activeDay: searchParams.activeDay,
    congressStartDay: searchParams.congressStartDay,
    congressEndDay: searchParams.congressEndDay,
    colorBy: searchParams.colorBy,
  });
};

const useSearchParamsDispatch = () => {
  const dispatch = useDispatch();

  return ({
    setSearchParams: data => dispatch(searchParamsActions.set(data)),
  });
};

export { useSearchParamsState, useSearchParamsDispatch };
