import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { selectPath, selectSearchQuery } from 'src/store/searchParams/selectors';
import { generateURL } from 'src/utils/path';


function* setSearchParams({ payload }) {
  const path = yield select(selectPath);
  const searchQuery = yield select(selectSearchQuery);

  yield put(push(generateURL(path, searchQuery, payload)));
}

export { setSearchParams };
