import React, { useEffect } from 'react';
import { useSpeakerListState, useSpeakerListDispatch } from 'src/store/speakers/list/hooks';
import { useSpeakerFavoritesState, useSpeakerFavoritesDispatch } from 'src/store/speakers/favorites/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { useSpeakerFiltersState } from 'src/store/speakers/filters/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { useInitState } from 'src/store/init/hooks';
import { List } from 'src/view/components/List';
import { NewSpeakersItem } from 'src/view/pages/Speakers/NewItem';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { SPEAKERS } from 'src/constants/list';


const SpeakerList = () => {
  const { venueTimezoneOffset, timezones } = useInitState();
  const { speakerList, isSpeakerListLoading, speakerTotalCount } = useSpeakerListState();
  const { getSpeakerList } = useSpeakerListDispatch();
  const { speakerFavorites, isSpeakerFavoritesLoading } = useSpeakerFavoritesState();
  const { fetchFavorites, addSpeakerToFavorites, removeSpeakerFromFavorites } = useSpeakerFavoritesDispatch();
  const { setSearchParams } = useSearchParamsDispatch();
  const { speakerFilters } = useSpeakerFiltersState();
  const { accessStatus, accessRoles } = useAccessState();
  const searchParams = useSearchParamsState();

  useEffect(() => {
    if (accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT)) {
      fetchFavorites();
    }
  }, []);

  return (
    <List
      listType={SPEAKERS}
      isLoading={isSpeakerListLoading || isSpeakerFavoritesLoading}
      list={speakerList}
      allFilters={speakerFilters}
      getList={getSpeakerList}
      favoriteEntities={speakerFavorites}
      addToFavorites={addSpeakerToFavorites}
      removeFromFavorites={removeSpeakerFromFavorites}
      totalCount={speakerTotalCount}
      setSearchParams={setSearchParams}
      venueTimezoneOffset={venueTimezoneOffset}
      timezones={timezones}
      item={NewSpeakersItem}
      {...searchParams}
    />
  );
};

export { SpeakerList };
