import { createActions } from 'src/modules/createActions';


const sessionListActions = createActions('session-list', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
  'SET_TOTAL_COUNT',
]);


export { sessionListActions };
