import React from 'react';
import parse from 'html-react-parser';


const Snippet = ({ selector, view }) => {
  const viewDOM = new DOMParser().parseFromString(view, 'text/html');
  const element = viewDOM.querySelector(selector);

  return <div dangerouslySetInnerHTML={{
    __html: element && element.innerHTML,
  }}/>;
};

const HTMLParser = ({ selector, view }) => {
  const viewDOM = new DOMParser().parseFromString(view, 'text/html');
  const element = viewDOM && viewDOM.querySelector(selector);
  const elementInnerHTML = element && element.innerHTML;

  return elementInnerHTML && parse(elementInnerHTML);
};

export { Snippet, HTMLParser };
