import { createActions } from 'src/modules/createActions';


const calendarFiltersActions = createActions('calendar-filters', [
  'FETCH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
]);


export { calendarFiltersActions };
