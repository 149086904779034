import { useSelector, useDispatch } from 'react-redux';
import { countryListActions } from 'src/store/countries/list/actions';
import {
  selectCountryList,
  selectCountryListLoading,
} from 'src/store/countries/list/selectors';


const useCountryListState = () => ({
  countryList: useSelector(selectCountryList),
  isCountryListLoading: useSelector(selectCountryListLoading),
});

const useCountryListDispatch = () => {
  const dispatch = useDispatch();

  return ({
    fetchCountryList: () => dispatch(countryListActions.fetch()),
  });
};

export { useCountryListState, useCountryListDispatch };
