import React, { useEffect, Fragment } from 'react';
import { SessionPlayer } from 'src/view/pages/SessionPlayerView/SessionPlayer';
import { SessionHeadline } from 'src/view/pages/SessionPlayerView/SessionHeadline';
import { SessionInfo } from 'src/view/pages/SessionPlayerView/SessionInfo';
import { useSessionItemDispatch, useSessionItemState } from 'src/store/sessions/item/hooks';
import { SessionComments } from 'src/view/pages/SessionPlayerView/SessionComments';
import { SessionCommentsInput } from 'src/view/pages/SessionPlayerView/SessionCommentsInput';
import { useConfigs } from 'src/hooks/useConfigs';
import { Container } from 'src/view/components/Container';
import { ContributionInfo } from 'src/view/pages/SessionPlayerView/ContributionInfo';
import { SessionModal } from 'src/view/pages/SessionPlayerView/SessionModal';
import { SessionTimezone } from 'src/view/pages/SessionPlayerView/SessionTimezone';
import { usePersonDispatch } from 'src/store/persons/item/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { ACCESS_ACCOUNT } from 'src/constants/access';


const SessionPlayerView = () => {
  const { isNEOSPlugin } = useConfigs();
  const { accessStatus } = useAccessState();
  const { sessionItem, sessionId } = useSessionItemState();
  const { getSessionItem } = useSessionItemDispatch();
  const { getPerson } = usePersonDispatch();

  useEffect(() => {
    let timer;

    if (!sessionItem) {
      getSessionItem(sessionId);
    }

    if (sessionItem) {
      timer = setInterval(() => getSessionItem(sessionId), 10000);
    }
    return () => clearInterval(timer);
  }, [sessionItem]);

  useEffect(() => {
    getPerson();
  }, [accessStatus === ACCESS_ACCOUNT]);


  const component = (
    <Fragment>
      <SessionModal />
      <SessionHeadline />
      <SessionPlayer />
      <SessionTimezone />
      <ContributionInfo />
      <SessionInfo />
      {sessionItem?.session?.enableComments && <SessionComments />}
      {sessionItem?.session?.enableComments && <SessionCommentsInput />}
    </Fragment>
  );

  return isNEOSPlugin ? component : (
    <Container>
      {component}
    </Container>
  );
};

export { SessionPlayerView };
