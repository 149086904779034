import { useDispatch } from 'react-redux';
import { sessionCommentsItemActions } from 'src/store/sessionComments/item/actions';


const useSessionCommentsItemDispatch = () => {
  const dispatch = useDispatch();

  return ({
    createComment: data => dispatch(sessionCommentsItemActions.create(data)),
  });
};

export { useSessionCommentsItemDispatch };
