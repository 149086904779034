import { createRosettaService } from '@globit/api-services';
import { config } from 'src/configs';
import { COUNTRY_KEY } from 'src/constants/country';


const createCountryService = (proxy, scope) => {
  const countryService = createRosettaService(config.api.services.rosetta, scope, 'translation', proxy);

  return {
    ...countryService,
    getCountryList: language => countryService.search({
      query: {
        and: [{
          iregex: {
            key: COUNTRY_KEY,
          },
        }, {
          eq: {
            language,
          },
        }],
      },
      limit: -1,
    }),
  };
};

export { createCountryService };
