import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { speakerListActions } from 'src/store/speakers/list/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { generateQuery } from 'src/utils/queries';
import { selectScope } from 'src/store/scope/selectors';
import { SPEAKERS } from 'src/constants/list';


function* fetchSpeakers({ payload: searchParams }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const speakers = yield call(selmaService.getSpeakerList, generateQuery({ viewType: SPEAKERS, ...searchParams }));

    yield put(speakerListActions.fetchSucceeded(speakers.items));
    yield put(speakerListActions.setTotalCount(speakers.totalCount));
  } catch (e) {
    yield put(speakerListActions.fetchFailed(e.message));
  }
}

export { fetchSpeakers };
