import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { personItemActions } from 'src/store/persons/item/actions';


const personItemReducer = combineReducers({
  entry: createReducer(null, {
    [personItemActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [personItemActions.updatePerson.type],
      stop: [personItemActions.fetchSucceeded.type, personItemActions.fetchFailed.type],
    },
  ]),
});

export { personItemReducer };
