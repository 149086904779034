import { useSelector, useDispatch } from 'react-redux';
import { accessTokenActions } from 'src/store/access/token/actions';


const useAccessState = () => ({
  accessToken: useSelector(state => state.access.token),
  accessStatus: useSelector(state => state.access.status),
  accessRoles: useSelector(state => state.access.roles),
  accessRef: useSelector(state => state.access.ref),
});


const useAccessDispatch = () => {
  const dispatch = useDispatch();

  return ({
    authenticate: formData => dispatch(accessTokenActions.setAccountToken(formData)),
  });
};

export { useAccessState, useAccessDispatch };
