import { createEvaEntityService } from '@globit/api-services';
import { config } from 'src/configs';


const createSessionWatchTimeService = (proxy, scope) => {
  const playerWatchTimeService = createEvaEntityService(config.api.services.eva, scope, 'sessionWatchTime', proxy);

  return {
    ...playerWatchTimeService,
  };
};

export { createSessionWatchTimeService };
