import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { sessionListActions } from 'src/store/sessions/list/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { generateQuery } from 'src/utils/queries';
import { selectScope } from 'src/store/scope/selectors';
import { SESSIONS } from 'src/constants/list';


function* fetchSessions({ payload: searchParams }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const sessions = yield call(selmaService.getSessionList, generateQuery({ viewType: SESSIONS, ...searchParams }));

    yield put(sessionListActions.fetchSucceeded(sessions.items));
    yield put(sessionListActions.setTotalCount(sessions.totalCount));
  } catch (e) {
    yield put(sessionListActions.fetchFailed(e.message));
  }
}

export { fetchSessions };
