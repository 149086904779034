import { createReducer } from '@reduxjs/toolkit';
import { accessRefActions } from 'src/store/access/ref/actions';


const accessRefReducer = createReducer(null, {
  [accessRefActions.set]: (state, action) => action.payload,
  [accessRefActions.clean]: () => null,
});

export { accessRefReducer };
