import { useSelector, useDispatch } from 'react-redux';
import { speakerFiltersActions } from 'src/store/speakers/filters/actions';
import { selectSpeakerFilters, selectSpeakerFiltersLoading } from 'src/store/speakers/filters/selectors';


const useSpeakerFiltersState = () => ({
  speakerFilters: useSelector(selectSpeakerFilters),
  isSpeakerFiltersLoading: useSelector(selectSpeakerFiltersLoading),
});

const useSpeakerFiltersDispatch = language => {
  const dispatch = useDispatch();

  return ({
    getSpeakerFilters: () => dispatch(speakerFiltersActions.fetch(language)),
  });
};

export { useSpeakerFiltersState, useSpeakerFiltersDispatch };
