import React from 'react';
import { CloseOutlined } from '@ant-design/icons';


const ResetIcon = ({ className, style, handleChange }) => (
  <CloseOutlined
    style={style}
    className={className}
    onClick={e => {
      e.preventDefault();
      handleChange([]);
    }} />
);

export { ResetIcon };
