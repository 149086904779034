import urlParser from 'url-parse';


const {
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_API_COAUTH,
  REACT_APP_API_SELMA,
  REACT_APP_API_ROSETTA,
  REACT_APP_API_FILUS,
  REACT_APP_API_EVA,
  REACT_APP_API_MELENA,
  REACT_APP_API_OPA,
  PUBLIC_URL,
} = process.env;

const { href, pathname } = urlParser(PUBLIC_URL || '/');

const config = {
  publicUrl: href,
  basename: pathname,
  api: {
    clientId: REACT_APP_CLIENT_ID,
    clientSecret: REACT_APP_CLIENT_SECRET,
    services: {
      coauth: REACT_APP_API_COAUTH,
      selma: REACT_APP_API_SELMA,
      rosetta: REACT_APP_API_ROSETTA,
      filus: REACT_APP_API_FILUS,
      eva: REACT_APP_API_EVA,
      melena: REACT_APP_API_MELENA,
      opa: REACT_APP_API_OPA,
    },
  },
};

export { config };
