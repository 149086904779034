import React from 'react';
import { BasePlayer } from 'src/view/components/VideoPlayer/components/BasePlayer';
import './styles.less';


const VideoPlayer = ({ videoPlayerRef, poster }) => {
  const initPlayer = reference => {
    const { IVSPlayer } = window;

    if (IVSPlayer?.isPlayerSupported) {
      const player = IVSPlayer.create(reference);

      player?.attachHTMLVideoElement(reference);
      // eslint-disable-next-line no-param-reassign
      videoPlayerRef.current = player;
    }
  };

  return <BasePlayer initializePlayer={initPlayer} poster={poster} />;
};

export { VideoPlayer };
