import React from 'react';
import moment from 'moment';
import { getUtcDate, getFullDate } from 'src/utils/date';
import { TimeBlock } from '../TimeBlock';


const TimeBlocks = ({ timeBlocks, hoursRange, sessions, timeFormat, ...rest }) =>
  (timeBlocks?.items || []).map(({ start, end, title }) => {
    const fullDate = sessions && sessions[0] && sessions[0].start_date_time;
    const hoursInBlock = (hoursRange || [])
      .filter(time => getFullDate(time).isSameOrAfter(getUtcDate(fullDate, start))
        && getFullDate(time).isBefore(getUtcDate(fullDate, end)))
      .map(time => moment(time).format(timeFormat))
      .filter(Boolean);

    const timeBlockSessions = (sessions || []).filter(({ start_date_time: startDate }) =>
      getFullDate(startDate).isSameOrAfter(getUtcDate(fullDate, start))
      && getFullDate(startDate).isBefore(getUtcDate(fullDate, end)));

    return (
      <TimeBlock
        hoursRange={hoursRange}
        hoursInBlock={hoursInBlock}
        start={start}
        end={end}
        title={title}
        timeFormat={timeFormat}
        sessions={timeBlockSessions}
        expandTimeBlockForActiveSession={timeBlocks?.expandTimeBlockForActiveSession}
        {...rest}
      />
    );
  });

export { TimeBlocks };
