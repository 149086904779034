import React from 'react';
import BEM from 'bem-class-names-builder';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { filterItemShape } from 'src/view/propTypes/filter';
import './styles.less';


const bem = new BEM('op-prefix-list-filter');

const PrefixListFilter = ({ options, onChange, style, value }) => {
  const { t } = useTranslation();

  return (
    <div className={bem}>
      <Select
        allowClear
        placeholder={t('searchByLastNamePlaceholder')}
        showSearch={false}
        onChange={selectedValue => onChange(options[selectedValue])}
        value={value && value.label}
      >
        {options.map((option, index) => (
          <Select.Option key={index}>
            <div
              style={{ ...style.item }}
              className={bem.elem('item').toString()}
            >
              {option.label}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

PrefixListFilter.propTypes = {
  options: PropTypes.arrayOf(filterItemShape).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  style: PropTypes.object,
};

PrefixListFilter.defaultProps = {
  style: {},
};

export { PrefixListFilter };
