import { useEffect } from 'react';
import { useCountryListDispatch } from 'src/store/countries/list/hooks';
import { useConfigs } from 'src/hooks/useConfigs';


const CountriesProvider = ({ children }) => {
  const { fetchCountryList } = useCountryListDispatch();
  const { language } = useConfigs();

  useEffect(() => {
    fetchCountryList(language);
  }, []);

  return children;
};

export { CountriesProvider };
