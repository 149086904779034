const getCountryName = (list = [], code = []) => {
  const isCountryCodeExist = code.length > 0 && code[0]?.data;
  const countryCode = isCountryCodeExist && code[0]?.data.trim();

  const country = list.find(({ source }) => source === countryCode);

  return country ? country?.translation : countryCode;
};

export { getCountryName };
