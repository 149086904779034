import { combineReducers } from 'redux';
import { sessionCommentsListReducer } from 'src/store/sessionComments/list/reducer';
import { sessionCommentsItemReducer } from 'src/store/sessionComments/item/reducer';


const sessionCommentsReducer = combineReducers({
  list: sessionCommentsListReducer,
  item: sessionCommentsItemReducer,
});

export { sessionCommentsReducer };
