import { call, put, select } from 'redux-saga/effects';
import { createDispatchService } from 'src/api/dispatchService';
import { sessionAdvertisementsActions } from 'src/store/sessions/advertisements/actions';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectAccessStatus } from 'src/store/access/selectors';
import { ACCESS_ACCOUNT } from 'src/constants/access';
import { PRIVATE_ADVERTISEMENTS, PUBLIC_ADVERTISEMENTS } from 'src/constants/advertisements';
import { selectScope } from 'src/store/scope/selectors';


function* fetchSessionAdvertisements() {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);

  try {
    const accessStatus = yield select(selectAccessStatus);
    const display = accessStatus === ACCESS_ACCOUNT ? PRIVATE_ADVERTISEMENTS : PUBLIC_ADVERTISEMENTS;
    const language = 'en-EN'; // TODO pass language dynamically after PLAT-2553 is done

    const advertisements = yield call(selmaService.getSessionAdvertisements, { display, language });

    yield put(sessionAdvertisementsActions.fetchSucceeded(advertisements.items));
  } catch (e) {
    yield put(sessionAdvertisementsActions.fetchFailed(e.message));
  }
}

export { fetchSessionAdvertisements };
