import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useSpeakerFavoritesState } from 'src/store/speakers/favorites/hooks';
import { SpeakerList } from 'src/view/pages/Speakers/List';
import { SpeakerFilters } from 'src/view/pages/Speakers/Filters';
import { SpeakerNavigation } from 'src/view/pages/Speakers/Navigation';
import { SearchBox } from 'src/view/components/SearchBox';
import { SPEAKER_LIST_LEFT, SPEAKER_LIST_RIGHT } from 'src/constants/advertisements';
import { SpeakerAdvertisement } from 'src/view/pages/Speakers/Advertisement';
import { useSearchParamsState } from 'src/store/searchParams/hooks';
import { ClearFilters } from 'src/view/components/ClearFilters';
import { useWindowSize } from 'src/hooks/useWindowSize';


const Speakers = () => {
  const { speakerFavorites } = useSpeakerFavoritesState();
  const { search } = useSearchParamsState();
  const [searchValue, setSearchValue] = useState(search);
  const [isShowFilters, setShowFilters] = useState(true);
  const windowSize = useWindowSize();

  useEffect(() => setShowFilters(windowSize.width < 850), [windowSize.width]);

  return (
    <div className="op-layout">
      <header>
        <SpeakerNavigation />
      </header>
      <div className="op-container">
        <div className="banner">
          <SpeakerAdvertisement side={SPEAKER_LIST_LEFT} />
        </div>
        <div className="op-content">
          <section>
            {windowSize.width < 850 && (
              <Button onClick={() => setShowFilters(!isShowFilters)} className='filter-button'>
                Filters
              </Button>)}
            <div className={`top-bar${isShowFilters ? '--hide' : ''}`}>
              <SearchBox favoriteEntities={speakerFavorites}
                searchValue={searchValue} setSearchValue={setSearchValue} />
              <div className='speakers-filters'>
                <SpeakerFilters />
              </div>
              <ClearFilters searchValue={searchValue} setSearchValue={setSearchValue} />
            </div>
            <SpeakerList />
          </section>
        </div>
        <div className="banner">
          <SpeakerAdvertisement side={SPEAKER_LIST_RIGHT} />
        </div>
      </div>
    </div>
  );
};

export { Speakers };
