import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createDispatchService } from 'src/api/dispatchService';
import { createAuthProxy } from 'src/store/access/utils';
import { speakerFiltersActions } from 'src/store/speakers/filters/actions';
import { store } from 'src/store/store';
import { selectScope } from 'src/store/scope/selectors';
import { generateURL, parseUrlSearchQuery } from 'src/utils/path';
import { selectPath, selectSearchQuery } from 'src/store/searchParams/selectors';
import { getDefaultFilters } from 'src/utils/filter';


function* fetchSpeakerFilters({ payload: language }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const selmaService = createDispatchService(authProxy, scope);
  const path = yield select(selectPath);
  const urlParams = yield select(selectSearchQuery);
  const { filters: urlFilters } = parseUrlSearchQuery(urlParams).query || {};

  try {
    const filters = yield call(selmaService.getSpeakerFilters, { language });

    if (!urlFilters) {
      const defaultFilters = getDefaultFilters(filters.items);

      yield put(push(generateURL(path, urlParams, { filters: defaultFilters })));
    }

    yield put(speakerFiltersActions.fetchSucceeded(filters.items));
  } catch (e) {
    yield put(speakerFiltersActions.fetchFailed(e.message));
  }
}

export { fetchSpeakerFilters };
