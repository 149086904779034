import { call, put, select } from 'redux-saga/effects';
import { createSessionWatchTimeService } from 'src/api/sessionWatchTimeService';
import { store } from 'src/store/store';
import { createAuthProxy } from 'src/store/access/utils';
import { selectScope } from 'src/store/scope/selectors';
import { sessionWatchTimeActions } from 'src/store/sessionWatchTime/actions';


function* createSessionWatchTime({ payload: data }) {
  const authProxy = createAuthProxy(store);
  const scope = yield select(selectScope);
  const sessionWatchTimeService = createSessionWatchTimeService(authProxy, scope);

  try {
    const sessionWatchTimeEntity = yield call(sessionWatchTimeService.create, data);

    yield put(sessionWatchTimeActions.createSucceeded([sessionWatchTimeEntity]));
  } catch (e) {
    yield put(sessionWatchTimeActions.createFailed(e.message));
  }
}

export { createSessionWatchTime };
