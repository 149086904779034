import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import BEM from 'bem-class-names-builder';
import { pageSizeOptions } from 'src/constants/pagination';
import { TimezoneContext } from 'src/contexts/TimezoneContext';
import { calculateItemTime } from 'src/utils/date';
import { Pagination } from 'src/view/components/Pagination';
import { Spinner } from 'src/view/components/Spinner';
import { TimezoneSwitcher } from 'src/view/components/TimezoneSwitcher';
import './styles.less';


const bem = new BEM('op-list');

const List = ({ isLoading, list, getList, totalCount, search, filters, allFilters, limit,
  offset, isFilterDisabled, getIsFavorite, setSearchParams, item: Item, favorites,
  addToFavorites, removeFromFavorites, colorBy }) => {
  useEffect(() => {
    if (allFilters) {
      getList({ filters, limit, offset, search, isFilterDisabled, favorites });
    }
  }, [
    JSON.stringify(filters),
    JSON.stringify(allFilters),
    JSON.stringify(favorites),
    search, limit, offset, isFilterDisabled,
  ]);
  const { venueTimezoneOffset, timezone, optionalTimeZones } = useContext(TimezoneContext);

  const listToDisplay = list.map(calculateItemTime(venueTimezoneOffset, timezone));

  const pagination = Math.ceil(totalCount / pageSizeOptions[0]) > 1 && (
    <Pagination
      totalCount={totalCount}
      limit={limit || pageSizeOptions[0]}
      offset={offset}
      onChange={params => setSearchParams({ ...params })}
    />
  );

  return (
    <Spinner isLoading={isLoading}>
      <div className={bem.mods('header', !pagination && 'header-pagination-missing')}>
        <div>{pagination}</div>
        <div className={bem.mods('timezone-switcher')}>
          {!!optionalTimeZones.length && !!listToDisplay.length && <TimezoneSwitcher />}
        </div>
      </div>
      <div className={bem.mods('body').mix(colorBy && `color-by-${colorBy}`)}>
        {listToDisplay.map(it => (
          <Item
            key={it.id}
            isFavorite={getIsFavorite(it)}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            {...it}
          />),
        )}
      </div>
      {pagination}
    </Spinner>
  );
};

List.propTypes = {
  list: PropTypes.array,
  getList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  search: PropTypes.string,
  filters: PropTypes.object,
  allFilters: PropTypes.array,
  isFilterDisabled: PropTypes.bool,
  limit: PropTypes.number,
  offset: PropTypes.number,
  setSearchParams: PropTypes.func.isRequired,
};

List.defaultProps = {
  list: [],
};

export { List };
