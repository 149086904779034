const selectInit = state => state.init.entry;
const selectIsInitLoading = state => state.init.loading > 0;
const selectCongress = state => (selectInit(state) || {}).congress;
const selectOnlineProgram = state => (selectCongress(state) || {}).onlineprogram;
const selectCongressRooms = state => (selectOnlineProgram(state) || {}).rooms;
const selectTimezones = state => (selectOnlineProgram(state) || {}).timezones;
const selectVenueTimezoneOffset = state => (selectCongress(state) || {}).venue?.timezoneOffset;
const selectCongressAppearance = state => (selectOnlineProgram(state) || {}).appearance;
const selectCongressTimeFormat = state => (selectOnlineProgram(state) || {}).dateTimeFormat?.time;
const selectCongressDateFormat = state => (selectOnlineProgram(state) || {}).dateTimeFormat?.date;
const selectCalendarTimeSlotHeight = state => {
  const timeSlotHeight = (selectOnlineProgram(state) || {})?.calendarView?.timeSlotHeight;

  return timeSlotHeight?.includes('px') ? timeSlotHeight.replace(/\D/g, '') : 200;
};
const selectAdvertisement = state => (selectOnlineProgram(state) || {}).advertisement;
const selectDelayedAdditionalDocsSessionIds = state =>
  (selectOnlineProgram(state) || {}).delayedAdditionalDocumentsSessionTypeIds;
const selectAdvertisementRotationTime = state => (selectAdvertisement(state) || {}).rotationTime;
const selectCongressStartDay = state => (selectCongress(state) || {}).startDate;
const selectCongressEndDay = state => (selectCongress(state) || {}).endDate;

export {
  selectInit,
  selectIsInitLoading,
  selectCongress,
  selectOnlineProgram,
  selectCongressRooms,
  selectTimezones,
  selectVenueTimezoneOffset,
  selectCongressAppearance,
  selectCongressTimeFormat,
  selectCongressDateFormat,
  selectAdvertisement,
  selectDelayedAdditionalDocsSessionIds,
  selectAdvertisementRotationTime,
  selectCongressStartDay,
  selectCongressEndDay,
  selectCalendarTimeSlotHeight,
};
