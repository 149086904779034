import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { sessionFiltersActions } from 'src/store/sessions/filters/actions';
import { createLoadingReducer } from 'src/modules/loadingReducer';


const sessionFiltersReducer = combineReducers({
  entries: createReducer(null, {
    [sessionFiltersActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionFiltersActions.fetch.type],
      stop: [sessionFiltersActions.fetchSucceeded.type, sessionFiltersActions.fetchFailed.type],
    },
  ]),
});

export { sessionFiltersReducer };
