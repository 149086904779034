import React from 'react';
import Icon from '@ant-design/icons/es';
import BEM from 'bem-class-names-builder';
import './styles.less';


const bem = new BEM('op-session-item-social-buttons');

const SocialButtons = ({ buttons, direction }) => (
  <div className={bem.mods({ direction })}>
    {
      buttons.map(({ title, icon, filledIcon, onClick, isHidden, isActive, component }) =>
        !isHidden && (
          <React.Fragment key={title}>
            <Icon
              className={bem.elem('button').mix(isActive && 'active').toString()}
              component={isActive ? filledIcon : icon}
              title={title}
              style={{ fontSize: '24px' }}
              onClick={onClick}
            />
            {component}
          </React.Fragment>
        ),
      )
    }
  </div>
);

export { SocialButtons };
