import React, { Fragment, useState } from 'react';
import BEM from 'bem-class-names-builder';
import { Input, Button, Checkbox } from 'antd';
import { useSessionCommentsItemDispatch } from 'src/store/sessionComments/item/hooks';
import { useConfigs } from 'src/hooks/useConfigs';
import './styles.less';


const bem = new BEM('comment-input');

/**
 * Component for creating a new comment and sending comment to specific session
 *
 * @category Main/Components
 */
const CommentsInput = () => {
  const [content, setContent] = useState('');
  const [step, setStep] = useState('input-field-step');
  const [isNotification, setIsNotification] = useState(false);

  const { sessionId } = useConfigs();
  const { createComment } = useSessionCommentsItemDispatch();

  const inputField = (
    <div className={bem.elem('input-field').toString()}>
      <Input
        className={bem.elem('box').toString()}
        onChange={e => setContent(e.target.value)}
        value={content} placeholder='Ask your question'
      />
      <Button
        className={bem.elem('btn').toString()}
        htmlType="submit"
        disabled={!content.length}
        type="primary"
        onClick={() => setStep('agreement-step')}
      >
        Send
      </Button>
    </div>
  );

  const agreement = (
    <Fragment>
      <div>
        <Checkbox
          value={isNotification}
          onChange={e => setIsNotification(e.target.checked)}
          className={bem.elem('checkbox').toString()}
        />
        <span>
          I would like to receive an answer by e-mail if my question is not answered during the session.
          For this purpose, I agree to pass on my e-mail address to the speaker.
        </span>
      </div>
      <div className={bem.elem('buttons').toString()}>
        <Button onClick={() => setStep('input-field-step')}>Back</Button>
        <Button
          type='primary'
          onClick={() => setStep('agreement-info-step')}
        >
          Save
        </Button>
      </div>
    </Fragment>
  );

  const info = (
    <Fragment>
      <div>Your question has been accepted and has been forwarded to the Chair.</div>
      <div className={bem.elem('buttons').toString()}>
        <Button onClick={() => {
          createComment({ content, status: 'new', sessionId, notification: isNotification });
          setContent('');
          setIsNotification(false);
          setStep('input-field-step');
        }}>Close</Button>
      </div>
    </Fragment>
  );

  const inputContent = {
    'input-field-step': inputField,
    'agreement-step': agreement,
    'agreement-info-step': info,
  };

  return (
    <div className={bem.elem('container').toString()}>
      {inputContent[step]}
    </div>);
};

export { CommentsInput };
