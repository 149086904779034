import { createActions } from 'src/modules/createActions';


const accessTokenActions = createActions('access-token', [
  'SET_TOKEN',
  'SET_TOKEN_SUCCEEDED',
  'SET_TOKEN_FAILED',
  'SET_CLIENT_TOKEN',
  'SET_ACCOUNT_TOKEN',
  'CHECK_TOKEN',
  'REFRESH_TOKEN',
  'CLEAN',
]);

export { accessTokenActions };
