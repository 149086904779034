import { useEffect } from 'react';
import { useInitDispatch } from 'src/store/init/hooks';


const InitProvider = ({ children }) => {
  const { getInit } = useInitDispatch();

  useEffect(() => {
    getInit();
  }, []);

  return children;
};

export { InitProvider };
