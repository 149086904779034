import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { sessionFavoritesActions } from 'src/store/sessions/favorites/actions';


const sessionFavoritesReducer = combineReducers({
  entries: createReducer([], {
    [sessionFavoritesActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionFavoritesActions.fetch.type],
      stop: [sessionFavoritesActions.fetchSucceeded.type, sessionFavoritesActions.fetchFailed.type],
    },
    {
      start: [sessionFavoritesActions.add.type],
      stop: [sessionFavoritesActions.addSucceeded.type, sessionFavoritesActions.addFailed.type],
    },
    {
      start: [sessionFavoritesActions.remove.type],
      stop: [sessionFavoritesActions.removeSucceeded.type, sessionFavoritesActions.removeFailed.type],
    },
  ]),
});

export { sessionFavoritesReducer };
