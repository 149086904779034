import React, { useEffect } from 'react';
import { useSpeakerAdvertisementsState, useSpeakerAdvertisementsDispatch } from 'src/store/speakers/advertisements/hooks';
import { useInitState } from 'src/store/init/hooks';
import { Advertisement } from 'src/view/components/Advertisement';


const SpeakerAdvertisement = props => {
  const { speakerAdvertisements, isSpeakerAdvertisementsLoading } = useSpeakerAdvertisementsState();
  const { getSpeakerAdvertisements } = useSpeakerAdvertisementsDispatch();
  const { advertisementRotationTime } = useInitState();

  useEffect(() => {
    getSpeakerAdvertisements();
  }, []);

  return (
    <Advertisement
      advertisements={speakerAdvertisements}
      isLoading={isSpeakerAdvertisementsLoading}
      rotationTime={advertisementRotationTime}
      {...props}
    />
  );
};

export { SpeakerAdvertisement };
