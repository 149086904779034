import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useConfigs } from 'src/hooks/useConfigs';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import { Snippet } from 'src/view/components/Snippet';
import { Layout } from 'src/view/components/Layout';


const ContributionInfo = () => {
  const { t } = useTranslation();
  const { isNEOSPlugin } = useConfigs();
  const { sessionItem } = useSessionItemState();
  const domElement = document.getElementById('op-session__contribution-info');
  const isContribution = Boolean(sessionItem && sessionItem?.session?.type === 'contribution');

  const component = sessionItem && isContribution && (
    <Layout>
      <div className={'op-session__contribution-info'}>
        <Snippet selector=".contribution-body" view={sessionItem.session.detailView} />
        <span className="op-session-contribution__dividerText">{t('contributionDetailSessionDividerText')}</span>
      </div>
    </Layout>
  );

  return isNEOSPlugin ? domElement && ReactDOM.createPortal(component, domElement) : component;
};

export { ContributionInfo };
