import { FILTERS } from 'src/constants/filters';


const parseUrlSearchQuery = searchQuery => {
  const searchParams = new URLSearchParams(searchQuery);

  return Array.from(searchParams.entries()).reduce((acc, [key, value]) =>
    ({ ...acc, [key]: key === 'query' ? JSON.parse(decodeURIComponent(escape(window.atob(value)))) : value }), {});
};

const generateURL = (pathName, searchQuery, params) => {
  const { query: existingQuery = {}, ...existingParams } = parseUrlSearchQuery(searchQuery);
  const searchParams = new URLSearchParams(existingParams);

  const query = Object.entries({ ...existingQuery, ...params })
    .reduce((acc, [key, value]) => {
      if (key === FILTERS) {
        const filters = Object.entries(value)
          .reduce((filtersAcc, [name, filter]) =>
            (filter.items.length ? { ...filtersAcc, [name]: filter } : filtersAcc), null);

        return filters ? { ...acc, filters } : acc;
      }


      return value ? { ...acc, [key]: value } : acc;
    }, {});

  const encodedQuery = Object.entries(query).length && window.btoa(unescape(encodeURIComponent(JSON.stringify(query))));

  if (encodedQuery) {
    searchParams.append('query', encodedQuery);
  }

  return pathName.concat('?', searchParams);
};

const getUrlWithoutHttps = endpoint => endpoint.replace('https://', '');

export { generateURL, parseUrlSearchQuery, getUrlWithoutHttps };
