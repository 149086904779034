import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import BEM from 'bem-class-names-builder';
import { Button } from 'antd';
import moment from 'moment';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { getSessionConfig, getCalendarConfig, getContributionAbstractContentUrl } from 'src/utils/session';
import { getFormattedTimeRange } from 'src/utils/date';
import { downloadFile } from 'src/utils/downloadFile';
import { getCountryName } from 'src/utils/country';
import { useInitState } from 'src/store/init/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { useCountryListState } from 'src/store/countries/list/hooks';
import { usePersonState } from 'src/store/persons/item/hooks';
import { VIEW_TYPE_INFO, VIEW_TYPE_OVERVIEW } from 'src/constants/session';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { BOOKMARK, BOOKMARK_ACTIVE, CARET, CARET_OPEN, ABSTRACT, ABSTRACT_CONTRIBUTION,
  SESSION_OPEN, CALENDAR, SHARE } from 'src/constants/template';
import { Modal } from 'src/view/components/Modal';
import { sessionShape } from 'src/view/propTypes/session';
import { Snippet } from 'src/view/components/Snippet';
import { ShareModal } from '../Item/components/ShareModal';
import './styles.less';


const bem = new BEM('tpl-op-session-item');

const NewSessionItem = session => {
  const { id, _self, listItemView, contributions, premium, playerUrl, start_date_time, end_date_time, isFavorite,
    legacyId, addToFavorites, removeFromFavorites, sessionViewType, detailView, session_type } = session;

  const sessionView = (sessionViewType === VIEW_TYPE_OVERVIEW || sessionViewType === VIEW_TYPE_INFO)
    ? detailView
    : listItemView;

  const { t } = useTranslation();
  const { accessStatus, accessRoles } = useAccessState();
  const { congress, onlineprogram, congressTimeFormat, congressDateFormat } = useInitState();
  const { countryList } = useCountryListState();
  const [abstractId, setAbstractId] = useState(null);
  const [contributionAbstractId, setContributionAbstractId] = useState(null);
  const [isShareModal, setIsShareModal] = useState(false);

  const downloadCalendarFile = () => {
    const sessionConfig = getSessionConfig(congress, session);
    const calendarConfig = getCalendarConfig(sessionConfig);

    downloadFile(calendarConfig, { type: 'text/calendar' }, `${sessionConfig.summary}.ics`);
  };

  const currentPlayerUrl = `${playerUrl || onlineprogram?.defaultPlayerUrl}?sessionId=${id}`;

  const time = getFormattedTimeRange(start_date_time, end_date_time, congressTimeFormat);
  const date = start_date_time.format(congressDateFormat?.short);

  const { person } = usePersonState();

  const adjustedListItemView = sessionView && parse(sessionView, {
    replace: ({ attribs, children, name }) => {
      if (attribs?.['data-op-replacement']?.includes('op-session-detail-page-url')) {
        const attributes = attributesToProps({
          ...attribs,
          href: `${onlineprogram?.sessionDetailPageUrl}?sessionId=${id}`,
        });

        return React.createElement(name, attributes, domToReact(children));
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-time') || attribs?.class?.includes('replaceable-time')) {
        const attributes = attributesToProps(attribs);

        return React.createElement(name, attributes, time);
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-date') || attribs?.class?.includes('replaceable-date')) {
        const attributes = attributesToProps(attribs);

        return React.createElement(name, attributes, date);
      }

      if (
        attribs?.['data-op-replacement']?.includes('op-session-type-translation')
        || attribs?.class?.includes('op-session__session-type-name')
      ) {
        const attributes = attributesToProps(attribs);
        const value = session_type?.id ? t(`op-session-type-${session_type?.id}`) : children;

        return React.createElement(name, attributes, value);
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-download-calendar') || attribs?.class?.includes(CALENDAR)) {
        const attributes = attributesToProps(attribs);

        return React.createElement(
          name,
          { ...attributes, title: t(attributes.title), onClick: downloadCalendarFile },
          domToReact(children),
        );
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-change-bookmark') || attribs?.class?.includes(BOOKMARK)) {
        const attributes = attributesToProps(attribs);

        const classNames = isFavorite
          ? `${attribs.class} ${BOOKMARK_ACTIVE}`
          : attribs.class;

        return React.createElement(
          name,
          { ...attributes,
            title: t(attributes.title),
            class: classNames,
            onClick: () => (isFavorite
              ? removeFromFavorites({
                _id: person._id,
                sessionFavorites: (person.sessionFavorites || []).filter(({ $ref }) => $ref !== _self.$ref),
              })
              : addToFavorites({
                _id: person._id,
                sessionFavorites: [...(person.sessionFavorites || []), { $ref: _self.$ref }],
              })
            ) },
          accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT) && person?._self?.$ref ? domToReact(children) : '',
        );
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-show-share-modal') || attribs?.class?.includes(SHARE)) {
        const attributes = attributesToProps(attribs);

        return React.createElement(
          name,
          { ...attributes, title: t(attributes.title), onClick: () => setIsShareModal(true) },
          domToReact(children));
      }

      if (attribs?.['data-op-replacement']?.includes('op-country-name') || attribs?.class?.includes('op-country-code')) {
        const attributes = attributesToProps(attribs);
        const countryName = getCountryName(countryList, children);

        return React.createElement(name, attributes, countryName);
      }

      return null;
    },
  });

  const contributionAbstractContentUrl = getContributionAbstractContentUrl(contributions, contributionAbstractId);

  const getItemViewSnippet = className =>
    adjustedListItemView.find(elem => elem.props?.className?.includes(className));

  const sessionItemRender = (
    <div className={bem.mix(
      !!session.topics?.length && `topic-${session.topics.map(topic => topic.id).join('-')}`,
      !!session.topics?.length && `topic-${session?.topics[0]?.id}`,
      session.track?.id && `track-${session.track?.id}`,
      session.session_type?.id && `session_type-${session.session_type?.id}`,
      session.start_date && `start_date-${moment(session.start_date).format('YYYY-MM-DD')}`,
      session.room?.id && `room-${session.room?.id}`,
      `session-${legacyId}`,
      (premium ? [...premium] : []),
    ).toString()}
         key={legacyId}
         onClick={event => {
           const targetClassList = event.target.classList;
           const currentTargetClassList = event.currentTarget.classList;

           const bookmark = targetClassList.contains(BOOKMARK);
           const bookmarkActive = targetClassList.contains(BOOKMARK_ACTIVE);
           const abstract = targetClassList.contains(ABSTRACT);
           const abstractContribution = targetClassList.contains(ABSTRACT_CONTRIBUTION);
           const caret = targetClassList.contains(CARET);
           const caretOpen = targetClassList.contains(CARET_OPEN);

           if (bookmark && !bookmarkActive) {
             targetClassList.add(BOOKMARK_ACTIVE);
           } else if (bookmark && bookmarkActive) {
             targetClassList.remove(BOOKMARK_ACTIVE);
           }

           if (abstract) {
             setAbstractId(event.target.getAttribute('data-abstract-id'));
           }

           if (abstractContribution && !abstract) {
             setContributionAbstractId(event.target.getAttribute('data-abstract-id'));
           }

           if (caret && !caretOpen) {
             targetClassList.add(CARET_OPEN);
             currentTargetClassList.add(SESSION_OPEN);
           } else if (caret && caretOpen) {
             targetClassList.remove(CARET_OPEN);
             currentTargetClassList.remove(SESSION_OPEN);
           }
         }}
    >
      {getItemViewSnippet('session-body')}
      <ShareModal
        title={t('shareTheEvent')}
        visible={isShareModal}
        handleClose={() => setIsShareModal(false)}
        value={onlineprogram?.sessionDetailPageUrl
          ? `${onlineprogram?.sessionDetailPageUrl}?sessionId=${id}`
          : currentPlayerUrl
        }
      />
      <Modal
        scrollable
        visible={contributionAbstractId}
        handleClose={() => setContributionAbstractId(null)}
        footer={[
          (contributionAbstractContentUrl
            && <Button
              className={bem.elem('download-abstract').toString()}
              key="download"
              type="primary"
              onClick={() => saveAs(contributionAbstractContentUrl)}
            >
              <DownloadOutlined />
              {t('downloadAbstract')}
            </Button>
          ),
          <Button key="submit" type="primary" onClick={() => setContributionAbstractId(null)}>
            {t('ok')}
          </Button>,
        ]}>
        <Snippet
          view={sessionView}
          selector={`.tpl-op-session-contribution-abstract__${contributionAbstractId}`}/>
      </Modal>
      <Modal
        scrollable
        visible={abstractId}
        handleClose={() => setAbstractId(null)}
        footer={[
          (session?.abstract?.content_url
            && <Button
              className={bem.elem('download-abstract').toString()}
              key="download"
              type="primary"
              onClick={() => saveAs(session?.abstract?.content_url)}
            >
              <DownloadOutlined />
              {t('downloadAbstract')}
            </Button>
          ),
          <Button key="submit" type="primary" onClick={() => setAbstractId(null)}>
            {t('ok')}
          </Button>,
        ]}>
        <Snippet
          view={sessionView}
          selector={`.tpl-op-session-abstract__${abstractId}`}/>
      </Modal>
    </div>
  );

  return sessionItemRender;
};

NewSessionItem.propTypes = {
  session: sessionShape,
};

NewSessionItem.defaultProps = {
  session: {},
};

export { NewSessionItem };
