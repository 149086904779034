import React, { useEffect } from 'react';
import { useSessionAdvertisementsState, useSessionAdvertisementsDispatch } from 'src/store/sessions/advertisements/hooks';
import { useInitState } from 'src/store/init/hooks';
import { Advertisement } from 'src/view/components/Advertisement';


const SessionAdvertisement = props => {
  const { sessionAdvertisements, isSessionAdvertisementsLoading } = useSessionAdvertisementsState();
  const { getSessionAdvertisements } = useSessionAdvertisementsDispatch();
  const { advertisementRotationTime } = useInitState();

  useEffect(() => {
    getSessionAdvertisements();
  }, []);

  return (
    <Advertisement
      advertisements={sessionAdvertisements}
      isLoading={isSessionAdvertisementsLoading}
      rotationTime={advertisementRotationTime}
      {...props}
    />
  );
};

export { SessionAdvertisement };
