import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useConfigs } from 'src/hooks/useConfigs';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import { VIEW_TYPE_INFO } from 'src/constants/session';
import { Layout } from 'src/view/components/Layout';
import { calculateItemTime } from 'src/utils/date';
import { TimezoneContext } from 'src/contexts/TimezoneContext';
import { NewSessionItem } from 'src/view/pages/Sessions/NewItem';
import './styles.less';


const SessionInfo = () => {
  const { isNEOSPlugin } = useConfigs();
  const { sessionItem } = useSessionItemState();
  const { venueTimezoneOffset, timezone } = useContext(TimezoneContext);
  const domElement = document.getElementById('op-session__info');
  const data = sessionItem && (sessionItem?.parent || sessionItem?.session);

  const getTimeForSession = calculateItemTime(venueTimezoneOffset, timezone);
  const updatedSessionByTime = data && getTimeForSession(data);

  const component = sessionItem && (
    <Layout>
      <NewSessionItem sessionViewType={VIEW_TYPE_INFO} {...updatedSessionByTime} />
    </Layout>
  );

  return isNEOSPlugin
    ? domElement && ReactDOM.createPortal(component, domElement)
    : component;
};

export { SessionInfo };
