import { useSelector, useDispatch } from 'react-redux';
import { fileActions } from 'src/store/files/actions';
import { selectIsFileLoading } from 'src/store/files/selectors';


const useFilesState = () => ({
  isFileLoading: useSelector(selectIsFileLoading),
});

const useFilesDispatch = () => {
  const dispatch = useDispatch();

  return ({
    downloadFile: (_file, filename) => dispatch(fileActions.download({ _file, filename })),
  });
};

export { useFilesState, useFilesDispatch };
