import { useSelector, useDispatch } from 'react-redux';
import { calendarFiltersActions } from 'src/store/calendar/filters/actions';
import {
  selectCalendarAdditionalFilters,
  selectCalendarFilters,
  selectCalendarFiltersLoading,
} from 'src/store/calendar/filters/selectors';


const useCalendarFiltersState = () => ({
  calendarFilters: useSelector(selectCalendarFilters),
  calendarAdditionalFilters: useSelector(selectCalendarAdditionalFilters),
  isCalendarFiltersLoading: useSelector(selectCalendarFiltersLoading),
});

const useCalendarFiltersDispatch = language => {
  const dispatch = useDispatch();

  return ({
    getCalendarFilters: () => dispatch(calendarFiltersActions.fetch(language)),
  });
};

export { useCalendarFiltersState, useCalendarFiltersDispatch };
