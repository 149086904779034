import { createReducer } from '@reduxjs/toolkit';
import { scopeActions } from 'src/store/scope/actions';


const scopeReducer = createReducer(null, {
  [scopeActions.set]: (state, action) => action.payload,
});

export { scopeReducer };

