import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { initActions } from 'src/store/init/actions';


const initReducer = combineReducers({
  entry: createReducer({}, {
    [initActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [initActions.fetch.type],
      stop: [initActions.fetchSucceeded.type, initActions.fetchFailed.type],
    },
  ]),
});

export { initReducer };

