import { createTokenAuthProxy } from '@globit/fetcher';
import { ACCESS_CLIENT, ACCESS_ACCOUNT } from 'src/constants/access';
import { accessTokenActions } from 'src/store/access/token/actions';


const createAuthProxy = store => createTokenAuthProxy({
  getToken: () => Promise.resolve()
    .then(() => store.getState().access.token),
  tokenInvalidOrExpired: () => store.dispatch(accessTokenActions.refreshToken()),
  numberOfTries: 1,
});


const getAccessOwner = token => (token._owner.$ref.includes(ACCESS_ACCOUNT) ? ACCESS_ACCOUNT : ACCESS_CLIENT);

const getAccessRoles = token => {
  const rootElement = document.getElementById('online-program');
  const scopeFromAttribute = rootElement.getAttribute('data-scope');
  const urlQuery = window.location.search;
  const urlParams = new URLSearchParams(urlQuery);
  const scope = scopeFromAttribute || urlParams.get('scope');

  return token._roles && token._roles[scope] ? Object.keys(token._roles[scope]) : [];
};


export { createAuthProxy, getAccessOwner, getAccessRoles };
