const RADIO_FILTER_TYPE = 'radios';
const CHECKBOX_FILTER_TYPE = 'checkboxes';
const SELECT_FILTER_TYPE = 'select';
const NAVIGATION_FILTER_TYPE = 'navigation';
const PREFIX_LIST_FILTER_TYPE = 'prefixlist';
const FILTERS = 'filters';

export {
  RADIO_FILTER_TYPE,
  CHECKBOX_FILTER_TYPE,
  SELECT_FILTER_TYPE,
  NAVIGATION_FILTER_TYPE,
  PREFIX_LIST_FILTER_TYPE,
  FILTERS,
};

