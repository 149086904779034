import React, { useEffect } from 'react';
import init from '@globit/cong-form-component/dist/init';
import { version } from '@globit/cong-form-component/tmp/version';
import { config } from 'src/configs';
import { useScopeState } from 'src/store/scope/hooks';
import { getUrlWithoutHttps } from 'src/utils/path';


const CongFormComponent = ({ setIsInit, params }) => {
  const { scope } = useScopeState();

  useEffect(() => {
    init(
      document.getElementById('cong-form'),
      document.getElementById('form-machine'),
      {
        ...params,
        clientId: config.api.clientId,
        clientSecret: config.api.clientSecret,
        scope,
        version,
        apis: {
          API_MELENA: getUrlWithoutHttps(config.api.services.melena),
          API_EVA: getUrlWithoutHttps(config.api.services.eva),
          API_SELMA: getUrlWithoutHttps(config.api.services.selma),
          API_OPA: getUrlWithoutHttps(config.api.services.opa),
          API_COAUTH: getUrlWithoutHttps(config.api.services.coauth),
          API_FILUS: getUrlWithoutHttps(config.api.services.filus),
        },
      });
    setIsInit(true);
  }, []);

  return (
    <div id={'cong-form'}>
      <div id={'form-machine'} />
    </div>
  );
};

export { CongFormComponent };
