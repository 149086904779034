import { createReducer } from '@reduxjs/toolkit';
import { accessRolesActions } from 'src/store/access/roles/actions';


const accessRolesReducer = createReducer([], {
  [accessRolesActions.set]: (state, action) => action.payload,
  [accessRolesActions.clean]: () => [],
});

export { accessRolesReducer };
