import { put, select } from 'redux-saga/effects';
import { scopeActions } from 'src/store/scope/actions';
import { errorActions } from 'src/store/error/actions';
import { selectScopeFromRouter } from 'src/store/scope/selectors';
import { ERROR_STATUS_WARNING } from 'src/constants/error';


function* scopeSaga() {
  const scopeFromUrl = yield select(selectScopeFromRouter);
  const scopeRootFromAttribute = document.getElementById('online-program').getAttribute('data-scope');

  const scope = scopeRootFromAttribute || scopeFromUrl;

  if (scope) {
    yield put(scopeActions.set(scope));
  } else {
    yield put(errorActions.set({ status: ERROR_STATUS_WARNING, messageKey: 'scopeRequiredError' }));
  }
}

export { scopeSaga };
