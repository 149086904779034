import { config } from 'src/configs';
import { parseUrlSearchQuery } from 'src/utils/path';


const getSpeakerRefById = id => {
  const query = parseUrlSearchQuery(window.location.search);
  const { dataset } = document.getElementById('online-program');

  const scope = dataset.scope || query.scope;

  return `${config.api.services.eva}/${scope}/person/${id}`;
};

export { getSpeakerRefById };
