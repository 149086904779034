import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createLoadingReducer } from 'src/modules/loadingReducer';
import { sessionAdvertisementsActions } from 'src/store/sessions/advertisements/actions';


const sessionAdvertisementsReducer = combineReducers({
  entries: createReducer([], {
    [sessionAdvertisementsActions.fetchSucceeded]: (state, action) => action.payload,
  }),
  loading: createLoadingReducer([
    {
      start: [sessionAdvertisementsActions.fetch.type],
      stop: [sessionAdvertisementsActions.fetchSucceeded.type, sessionAdvertisementsActions.fetchFailed.type],
    },
  ]),
});

export { sessionAdvertisementsReducer };
