import { useSelector, useDispatch } from 'react-redux';
import { sessionCommentsListActions } from 'src/store/sessionComments/list/actions';
import {
  selectSessionCommentsList,
  selectSessionCommentsListLoading,
  selectSessionCommentsListTotalCount,
} from 'src/store/sessionComments/list/selectors';


const useSessionCommentsListState = () => ({
  sessionComments: useSelector(selectSessionCommentsList),
  isSessionCommentsLoading: useSelector(selectSessionCommentsListLoading),
  totalCount: useSelector(selectSessionCommentsListTotalCount),
});

const useSessionCommentsListDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getSessionComments: sessionId => dispatch(sessionCommentsListActions.fetch(sessionId)),
  });
};

export { useSessionCommentsListState, useSessionCommentsListDispatch };
