import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createRootReducer } from 'src/store/rootReducer';
import { rootSaga } from 'src/store/rootSaga';
import { config } from 'src/configs';


const history = createBrowserHistory({ basename: config.basename });
const sagaMiddleware = createSagaMiddleware();

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(
  applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
  ),
  ...enhancers,
);

const store = createStore(
  createRootReducer(history),
  composedEnhancers,
);

sagaMiddleware.run(rootSaga);

export { store, history };
