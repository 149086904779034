import { combineReducers } from 'redux';
import { sessionListReducer } from 'src/store/sessions/list/reducer';
import { sessionFiltersReducer } from 'src/store/sessions/filters/reducer';
import { sessionAdvertisementsReducer } from 'src/store/sessions/advertisements/reducer';
import { sessionItemReducer } from 'src/store/sessions/item/reducer';


const sessionReducer = combineReducers({
  list: sessionListReducer,
  item: sessionItemReducer,
  filters: sessionFiltersReducer,
  advertisements: sessionAdvertisementsReducer,
});

export { sessionReducer };
