import { useSelector, useDispatch } from 'react-redux';
import { sessionFiltersActions } from 'src/store/sessions/filters/actions';
import {
  selectSessionAdditionalFilters,
  selectSessionFilters,
  selectSessionFiltersLoading,
} from 'src/store/sessions/filters/selectors';


const useSessionFiltersState = () => ({
  sessionFilters: useSelector(selectSessionFilters),
  sessionAdditionalFilters: useSelector(selectSessionAdditionalFilters),
  isSessionFiltersLoading: useSelector(selectSessionFiltersLoading),
});

const useSessionFiltersDispatch = language => {
  const dispatch = useDispatch();

  return ({
    getSessionFilters: () => dispatch(sessionFiltersActions.fetch(language)),
  });
};

export { useSessionFiltersState, useSessionFiltersDispatch };
